import { serverToClientDate } from '@utils/time.utils';

import RoomDTO from './room.dto';
import { Room } from '@models/room.class';

export class RoomMapper {
  static fromDTO(dto: RoomDTO): Room {
    return new Room({
      id: dto.idRoom,
      name: dto.name,
      description: dto.description,
      dates: {
        created: dto.createdAt ? serverToClientDate(dto.createdAt) : undefined,
        modified: serverToClientDate(dto.lastModification),
        pictureChanged: serverToClientDate(dto.lastImageChange),
        lastEvent: serverToClientDate(dto.lastActivity),
      },
      unread: dto.readCount,
      bidirectional: true, // dto.bidirectional === 1,
    });
  }

  /*
  static toDTO(entity) {
    return {
      idUser: entity.idUser,
      name: entity.name,
      surname: entity.surname,
      authorized: entity.authorized,
    };
  }
  */
}
