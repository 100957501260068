import { serverToClientDate } from '@utils/time.utils';

import DiscoverDTO from './discover.dto';
import { Discover } from '@models/discover.class';

export class DiscoverMapper {
  static fromDTO(dto: DiscoverDTO): Discover {
    return new Discover({
      id: dto.idRoom,
      idDiscover: dto.idDiscover,
      name: dto.name,
      description: dto.description,
      dates: {
        // TODO: Enable when server includes these dates
        // created: unixToDate(dto.createdAt),
        // modified: unixToDate(dto.lastModification),
        pictureChanged: serverToClientDate(dto.lastImageChange),
      },
      index: dto.position,
      // picture: dto.image,
      // TODO: Enable when server includes these dates
      // bidirectional: dto.bidirectional === 1,
      bidirectional: false,
      vinculated: dto.vinculated === 'true',
    });
  }
}
