import { inject, isDevMode } from '@angular/core';

import {
  getState,
  patchState,
  signalStore,
  watchState,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';

import { Sidebar } from '@enums/sidebar.enum';

import { Chat } from '@models/chat.interface';

import { initialUIState, UIState } from '@state/state/ui.state';

import { StorageService } from '@services/storage/storage.service';

export const TAG = 'UI Store';

export type UIStore = InstanceType<typeof UIStore>;
export const UIStore = signalStore(
  withState(initialUIState), // ! If we initialize the state here, it will overwrite the state from the storage on store initialization
  withMethods((store, storageService = inject(StorageService)) => ({
    // Generic setters
    set<K extends keyof UIState>(property: K, value: UIState[K]): void {
      patchState(store, (state) => ({ [property]: value }));
    },

    reset(property?: keyof UIState): void {
      if (!property) {
        patchState(store, (state) => initialUIState);
        return;
      }
      patchState(store, (state) => ({ [property]: initialUIState[property] }));
    },

    // Hydration methods
    hydrate: (): void => {
      console.debug(`[${TAG}] Starting hydration...`);
      if (storageService.check(TAG)) {
        const stored = storageService.get<UIState>(TAG);
        console.debug(`[${TAG}] Hydrating...`, stored);
        if (stored !== null)
          // ? Only restore some properties of the stored state
          patchState(store, (state) => ({
            theme: stored.theme,
            language: stored.language,
            chat: stored.chat,
          }));
      } else {
        console.debug(`[${TAG}] No previous state found`);
      }
    },

    persist(): void {
      console.debug(`[${TAG}] Persisting state...`);
      storageService.set(TAG, getState(store));
    },

    // Custom methods
    selectChat: (chat?: Chat): void => {
      // ? We need to reset the sidebar when selecting a new chat or unselecting the current one
      patchState(store, (state) => ({
        chat,
        ...(!chat ||
        chat.id !== state.chat?.id ||
        chat.type !== state.chat?.type
          ? {
              sidebar: undefined,
              message: undefined,
            }
          : {}),
      }));
    },

    messageInfo: (message: string): void => {
      // ? We want to set the sidebar to the message info view when clicking on a message
      patchState(store, (state) => ({
        sidebar: { content: Sidebar.MessageInfo, message },
      }));
    },
  })),
  withHooks({
    onInit: (store) => {
      if (isDevMode()) console.debug(`[${TAG}] Store initialized`);

      // ? Effects
      // https://next.ngrx.io/guide/signals/signal-store/state-tracking#using-getstate-and-effect
      // effect(() => {}, {});

      store.hydrate();

      watchState(store, (state) => {
        if (isDevMode()) console.debug(`[${TAG}] State updated:`, state);
        store.persist();
      });

      /*
      ! For users, we want to store all changes
      if (isDevMode())
        watchState(store, (state) =>
          console.debug(`[${TAG}] State updated:`, state)
        );

      // ? Instead of watching the state and persisting it everytime it changes, we persist it every x minutes
      const SAVE_INTERVAL_MINUTES = 5; // Set the interval time in minutes
      interval(SAVE_INTERVAL_MINUTES * 60 * 1000)
        .pipe(takeUntilDestroyed())
        .subscribe(() => {
          if (isDevMode())
            console.debug(`[${TAG}] Persisting state at interval`);
          store.persist();
        });
      */
    },
    onDestroy: (store) => {
      if (isDevMode())
        console.debug(`[${TAG}] Store destroyed`, getState(store));
      store.persist();
    },
  })
);
