import { Injectable, isDevMode } from '@angular/core';

import { map, Observable, of } from 'rxjs';

import mime from 'mime';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor() {}

  select({
    accept,
    multiple,
  }: {
    accept?: string;
    multiple?: boolean;
  } = {}): Observable<File | undefined> {
    return new Observable<File | undefined>((subscriber) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = accept || '*/*';
      input.multiple = multiple || false;
      input.onchange = () => {
        if (isDevMode()) console.log('Selected files:', input.files);
        subscriber.next(input.files?.item(0) || undefined);
        subscriber.complete();
      };
      input.click();
    });
  }

  download(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  }

  static blobToURL<T>(
    blob: Blob,
    provider: (src: undefined | string | ArrayBuffer) => T = (src) => src as T
  ): Observable<T> {
    if (blob === undefined) return of(provider(undefined));
    return new Observable<string | ArrayBuffer>((subscriber) => {
      const reader = new FileReader();
      reader.onload = () => {
        subscriber.next(reader.result!);
        subscriber.complete();
      };
      reader.onerror = (error) => {
        subscriber.error(error);
      };
      reader.readAsDataURL(blob);
    }).pipe(map(provider));
  }

  static extension(mimetype: string): string {
    return mime.getExtension(mimetype) ?? mimetype;
  }

  static mime(extension: string): string {
    return mime.getType(extension) ?? extension;
  }
}
