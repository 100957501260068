import { MessageType } from '@enums/messageType.enum';

import { AudioMessage, IAudioMessage } from './messages/audio-message.class';
import {
  ContactMessage,
  IContactMessage,
} from './messages/contact-message.class';
import { FileMessage, IFileMessage } from './messages/file-message.class';
import { IImageMessage, ImageMessage } from './messages/image-message.class';
import {
  ILocationMessage,
  LocationMessage,
} from './messages/location-message.class';
import { ITextMessage, TextMessage } from './messages/text-message.class';
import { IVideoMessage, VideoMessage } from './messages/video-message.class';

export type IMessage =
  | ITextMessage
  | IImageMessage
  | IVideoMessage
  | IAudioMessage
  | IFileMessage
  | ILocationMessage
  | IContactMessage;

export type Message =
  | TextMessage
  | ImageMessage
  | VideoMessage
  | AudioMessage
  | FileMessage
  | LocationMessage
  | ContactMessage;

export function instanceOfMessage(generic?: any): generic is Message {
  return (
    generic instanceof TextMessage ||
    generic instanceof ImageMessage ||
    generic instanceof VideoMessage ||
    generic instanceof AudioMessage ||
    generic instanceof FileMessage ||
    generic instanceof LocationMessage ||
    generic instanceof ContactMessage
  );
}

export function instanceToClass(generic: IMessage): Message {
  switch (generic.type) {
    case MessageType.TEXT:
      return new TextMessage(generic);
    case MessageType.IMAGE:
      return new ImageMessage(generic);
    case MessageType.VIDEO:
      return new VideoMessage(generic);
    case MessageType.AUDIO:
      return new AudioMessage(generic);
    case MessageType.FILE:
      return new FileMessage(generic);
    case MessageType.LOCATION:
      return new LocationMessage(generic);
    case MessageType.CONTACT:
      return new ContactMessage(generic);
    default:
      return generic;
  }
}
