import { RoomMember } from './room-member.class';

interface IRoomMembers {
  members: number[];
  administrators: number[];
}

export class RoomMembers implements IRoomMembers {
  members: number[];
  administrators: number[];

  constructor(members: RoomMember[]) {
    this.members = members.map((member) => member.id);
    this.administrators = members
      .filter((member) => member.administrator)
      .map((member) => member.id);
  }
}
