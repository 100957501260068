import { MessageType } from '@enums/messageType.enum';

import { BaseMessage, IBaseMessage } from './base/Message.base';

export interface ITextMessage extends IBaseMessage {
  type: MessageType.TEXT;

  preview?: {
    url: string;
    title: string;
    description?: string;
    image?: Blob;
    video?: boolean;
  };
}

export class TextMessage extends BaseMessage implements ITextMessage {
  override readonly type: MessageType.TEXT = MessageType.TEXT;

  constructor(message: Omit<ITextMessage, 'type'>) {
    super({ ...message, type: MessageType.TEXT });
  }
}
