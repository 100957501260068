// ? https://socket.io/docs/v4/client-api/#events
// ? https://socket.io/docs/v4/client-api/#events-1

export enum SocketEvent {
  ConnectError = 'connect_error', // Emitted when an error occurs during the connection.
  Connect = 'connect', // Emitted when the socket successfully connects to the server.
  Ping = 'ping', // Emitted when a ping packet is received from the server.
  Error = 'error', // Emitted when an error occurs.
  Disconnect = 'disconnect', // Emitted when the socket is disconnected.
  ReconnectAttempt = 'reconnect_attempt', // Emitted when a reconnect attempt is made.
  Reconnect = 'reconnect', // Emitted when the socket successfully reconnects to the server.
}
