import { SemanticLevel } from './semantic.enum';

export type ThemeType =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'accent'
  | SemanticLevel;

export function transformIntoTailwindTheme(theme: ThemeType): string {
  switch (theme) {
    case 'primary':
      return 'primary';
    case 'secondary':
      return 'secondary';
    case 'accent':
      return 'accent';
    case 'success':
      return 'success';
    case 'error':
      return 'danger';
    case 'warning':
      return 'warn';
    case 'info':
      return 'info';
    default:
      return 'default';
  }
}
