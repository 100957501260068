import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable, tap } from 'rxjs';

import { configuration } from '@configuration/configuration';

import { dateToUnix, unixToDate } from '@utils/time.utils';

import { ModificationState } from '@enums/modificationState.enum';

import GetFileMessagesResponseDTO from './models/get-file-messages.response.dto';
import GetMessageStatusResponseDTO from './models/get-message-status.response.dto';
import GetMessagesResponseDTO from './models/get-messages.response.dto';
import { HistoricOptions } from './models/historic-options.interface';
import { MessageDTO } from './models/message.dto';
import { MessageMapper } from './models/message.mapper';
import { PaginationOptions } from './models/pagination-options.interface';
import ReadRoomMessagesRequestDTO from './models/read-messages.request.dto';
import ResendMessageRequestDTO from './models/resend-message.request.dto';
import SendFileResponseDTO from './models/send-file.response.dto';
import { MessageStatus } from '@models/message-status.class';
import { Message } from '@models/message.type';

import { MessagesStore } from '@state/stores/messages.store';

@Injectable({
  providedIn: 'root',
})
export class RoomMessagesService {
  private url: string;

  constructor(
    private http: HttpClient,
    private readonly messagesStore: MessagesStore
  ) {
    this.url = configuration.apiUrl;
  }

  getMessages(
    id: number,
    options?: HistoricOptions | PaginationOptions
  ): Observable<Message[]> {
    let queryParams = new HttpParams();
    let endpoint: 'historic' | 'messages';

    // ? If no options are provided, it will get all messages
    if (!options || Object.keys(options).length === 0) endpoint = 'historic';
    // ? If a date is provided, it will get all messages after that date
    else if (Object.keys(options).includes('date')) {
      endpoint = 'historic';
      queryParams = queryParams.set(
        'changes',
        dateToUnix((options as HistoricOptions).date).toString() // ? Date should be sent to the server in unix format
      );
    }
    // ? If pagination options are provided, it will get the messages paginated
    else {
      endpoint = 'messages';
      if (Object.keys(options).includes('page'))
        queryParams = queryParams.set(
          'page',
          (options as PaginationOptions).page!.toString()
        );
      if (Object.keys(options).includes('amount'))
        queryParams = queryParams.set(
          'amount',
          (options as PaginationOptions).amount!.toString()
        );
    }

    return this.http
      .get<GetMessagesResponseDTO>(`${this.url}/rooms/${id}/${endpoint}`, {
        params: queryParams,
      })
      .pipe(
        map((response) =>
          response.messages.map((message) => MessageMapper.fromDTO(message))
        )
      );
  }

  getFileMessages(
    id: number,
    options?: { page?: number }
  ): Observable<Message[]> {
    let queryParams = new HttpParams();
    if (options?.page)
      queryParams = queryParams.set('page', options.page.toString());

    return this.http
      .get<GetFileMessagesResponseDTO>(`${this.url}/rooms/${id}/allfiles`, {
        params: queryParams,
      })
      .pipe(
        map((response) =>
          response.files.map((message) => MessageMapper.fromDTO(message))
        )
      );
  }

  getFile(
    id: number,
    idMessage: string,
    params: {
      authorization?: string;
      deviceid?: string;
      requestid?: string;
    } = {}
  ): Observable<Blob> {
    let queryParams = new HttpParams({
      fromObject: params,
    });

    return this.http.get(
      `${this.url}/rooms/${id}/getfilemessage/${idMessage}`,
      {
        params: queryParams,
        responseType: 'blob',
      }
    );
  }

  getFileURL(
    id: number,
    idMessage: string,
    params?: {
      authorization?: string;
      deviceid?: string;
      requestid?: string;
    }
  ): string {
    let queryParams = new HttpParams({
      fromObject: params,
    });

    const httpRequest = new HttpRequest(
      'GET',
      `${this.url}/rooms/${id}/getfilemessage/${idMessage}`,
      {
        params: queryParams,
        responseType: 'blob',
      }
    );
    return httpRequest.urlWithParams;
  }

  /*
  // ? Messages are sent through sockets
  sendMessage(id: number): Observable<string> {
    return this.http
      .post<SendMessageResponseDTO>(`${this.url}/rooms/${idRoom}/messages`, {
        message,
      })
      .pipe(map((response) => MessageMapper.fromDTO(response.message)));
  }
  */

  sendFile(
    id: number,
    idMessage: string,
    file: Blob,
    message: string,
    replyId?: string
  ): Observable<string> {
    const formData = new FormData();
    formData.append('internalId', idMessage);
    formData.append('file', file);
    formData.append('message', message);
    if (replyId) formData.append('idMessageReply', replyId);

    return this.http
      .post<SendFileResponseDTO>(`${this.url}/rooms/${id}/sendfile`, formData)
      .pipe(map((response) => response._id));
  }

  resendMessage(
    id: number,
    idMessage: string,
    destinations: {
      id: number;
      oto: boolean;
    }[]
  ): Observable<void> {
    const body: ResendMessageRequestDTO = {
      fromIdMessage: idMessage,
      fromIdRoom: id,
      oneToOne: 0,
      destinations: destinations.map((destination) => {
        return {
          toIdRoom: destination.id,
          oneToOne: destination.oto ? 1 : 0,
        };
      }),
    };

    return this.http.post<void>(`${this.url}/rooms/messages/resend`, body);
  }

  deleteMessage(id: number, idMessage: string): Observable<string> {
    return this.http
      .delete<MessageDTO>(`${this.url}/rooms/${id}/${idMessage}`)
      .pipe(
        map((message) => message.internalId),
        tap((id) =>
          this.messagesStore.update(id, (message) => ({
            state: ModificationState.DELETED,
            dates: {
              ...message.dates,
              deletedDate: new Date(),
            },
          }))
        )
      );
  }

  readMessages(id: number, date: Date, amount: number): Observable<void> {
    const body: ReadRoomMessagesRequestDTO = {
      dateAction: dateToUnix(date),
      messagesToClean: amount,
    };

    return this.http.put<void>(`${this.url}/rooms/${id}/readmessages`, body);
  }

  getMessageStatus(
    idMessage: string,
    idRoom: number
  ): Observable<MessageStatus> {
    return this.http
      .get<GetMessageStatusResponseDTO>(
        `${this.url}/rooms/${idRoom}/${idMessage}/info`
      )
      .pipe(
        map((response) => {
          return new MessageStatus({
            unreceived: response.notReceived,
            received: response.received.map((user) => {
              return {
                id: user.idUser,
                date: unixToDate(user.date),
              };
            }),
            readed: response.readed.map((user) => {
              return {
                id: user.idUser,
                date: unixToDate(user.date),
              };
            }),
          });
        })
      );
  }
}
