import { ServerFileType } from './base/base-message.dto';
import { MessageDTO } from './message.dto';
import { Message } from '@models/message.type';
import { AudioMessage } from '@models/messages/audio-message.class';
import { FileMessage } from '@models/messages/file-message.class';
import { ImageMessage } from '@models/messages/image-message.class';
import { TextMessage } from '@models/messages/text-message.class';
import { VideoMessage } from '@models/messages/video-message.class';

export class MessageMapper {
  static fromDTO(dto: MessageDTO): Message {
    if (dto.fileType) {
      switch (dto.fileType) {
        case ServerFileType.IMAGE:
          if (!dto.extension)
            throw new Error('Extension is required', {
              cause: dto,
            });
          if (!dto.thumbnail)
            throw new Error('Thumbnail is required', {
              cause: dto,
            });
          return new ImageMessage({
            synchronized: true,
            id: dto.internalId,
            ids: {
              server: dto._id,
              sender: dto.idUser,
              receiver: dto.toIdUser,
              room: dto.idRoom,
              replyId: dto.replyMessage?.idMessage,
            },
            dates: {
              sentDate: new Date(dto.date),
            },
            message: dto.message,
            extension: dto.extension,
            // file: // ? The file is downloaded when the user clicks on the message thumbnail, not when the message is received from the server
            /*
            // Transform the base64 dto.thumbnail string into a Blob
            thumbnail: new Blob([dto.thumbnail], {
              type: `image/${dto.extension}`,
            }),
            */
            thumbnail: dto.thumbnail,
            // size: dto.fileSize,
            reply: dto.replyMessage && {
              id: dto.replyMessage.idMessage,
              message: dto.replyMessage.text,
              file: dto.replyMessage.file,
              sender: dto.replyMessage.idUser,
              date: new Date(dto.replyMessage.date),
              state: dto.replyMessage.modifiedState,
            },
            state: dto.modifiedState,
          });
        case ServerFileType.VIDEO:
          if (!dto.extension)
            throw new Error('Extension is required', {
              cause: dto,
            });
          if (!dto.thumbnail)
            throw new Error('Thumbnail is required', {
              cause: dto,
            });
          /*
          TODO: Uncomment this when the server sends the duration of the video
          if (!dto.fileDuration)
            throw new Error('Duration is required', {
              cause: dto,
            });
          */

          return new VideoMessage({
            synchronized: true,
            id: dto.internalId,
            ids: {
              server: dto._id,
              sender: dto.idUser,
              receiver: dto.toIdUser,
              room: dto.idRoom,
              replyId: dto.replyMessage?.idMessage,
            },
            dates: {
              sentDate: new Date(dto.date),
            },
            message: dto.message,
            extension: dto.extension,
            /*
            thumbnail: new Blob([dto.thumbnail], {
              type: `image/${dto.extension}`,
            }),
            */
            thumbnail: dto.thumbnail,
            // size: dto.fileSize,
            // duration: dto.fileDuration,
            // ? For now, the duration is calculated from the dto.message property (which contains the duration in the format HH:mm:ss)
            // Find a dd:dd or a dd:dd:dd pattern in the message and convert it to seconds
            duration:
              dto.message
                .match(/(\d{1,2}:){1,2}\d{1,2}/g)
                ?.map((time) => time.split(':'))
                ?.flat()
                .reduce((acc, time) => {
                  return acc * 60 + parseInt(time);
                }, 0) || 0,
            reply: dto.replyMessage && {
              id: dto.replyMessage.idMessage,
              message: dto.replyMessage.text,
              file: dto.replyMessage.file,
              sender: dto.replyMessage.idUser,
              date: new Date(dto.replyMessage.date),
              state: dto.replyMessage.modifiedState,
            },
            state: dto.modifiedState,
          });
        case ServerFileType.AUDIO:
          if (!dto.extension)
            throw new Error('Extension is required', {
              cause: dto,
            });
          if (!dto.fileDuration)
            throw new Error('Duration is required', {
              cause: dto,
            });

          return new AudioMessage({
            synchronized: true,
            id: dto.internalId,
            ids: {
              server: dto._id,
              sender: dto.idUser,
              receiver: dto.toIdUser,
              room: dto.idRoom,
              replyId: dto.replyMessage?.idMessage,
            },
            dates: {
              sentDate: new Date(dto.date),
            },
            extension: dto.extension,
            // size: dto.fileSize,
            duration: dto.fileDuration,
            reply: dto.replyMessage && {
              id: dto.replyMessage.idMessage,
              message: dto.replyMessage.text,
              file: dto.replyMessage.file,
              sender: dto.replyMessage.idUser,
              date: new Date(dto.replyMessage.date),
              state: dto.replyMessage.modifiedState,
            },
            state: dto.modifiedState,
          });
        case ServerFileType.FILE:
          if (!dto.extension)
            throw new Error('Extension is required', {
              cause: dto,
            });
          return new FileMessage({
            synchronized: true,
            id: dto.internalId,
            ids: {
              server: dto._id,
              sender: dto.idUser,
              receiver: dto.toIdUser,
              room: dto.idRoom,
              replyId: dto.replyMessage?.idMessage,
            },
            dates: {
              sentDate: new Date(dto.date),
            },
            // message: dto.message,
            extension: dto.extension,
            thumbnail: dto.thumbnail,
            // size: dto.fileSize,
            reply: dto.replyMessage && {
              id: dto.replyMessage.idMessage,
              message: dto.replyMessage.text,
              file: dto.replyMessage.file,
              sender: dto.replyMessage.idUser,
              date: new Date(dto.replyMessage.date),
              state: dto.replyMessage.modifiedState,
            },
            state: dto.modifiedState,
          });
      }
    }

    return new TextMessage({
      synchronized: true,
      id: dto.internalId,
      ids: {
        server: dto._id,
        sender: dto.idUser,
        receiver: dto.toIdUser,
        room: dto.idRoom,
        replyId: dto.replyMessage?.idMessage,
      },
      dates: {
        sentDate: new Date(dto.date),
      },
      message: dto.message,
      preview: dto.linkPreview && {
        url: dto.linkPreview?.url,
        title: dto.linkPreview?.title,
        description: dto.linkPreview?.description || undefined,
      },
      reply: dto.replyMessage && {
        id: dto.replyMessage.idMessage,
        message: dto.replyMessage.text,
        file: dto.replyMessage.file,
        sender: dto.replyMessage.idUser,
        date: new Date(dto.replyMessage.date),
        state: dto.replyMessage.modifiedState,
      },
      state: dto.modifiedState,
    });
  }

  /*
  static toDTO(user: User): UserDTO {
    return {
      id: user.id,
      name: user.name,
      surname: user.surname,
      email: user.email,
      bio: user.bio,
      imageProfile: user.imageProfile,
      imageCover: user.imageCover,
      createdAt: user.createdAt.toISOString(),
      updatedAt: user.updatedAt.toISOString(),
    };
  }
  */
}
