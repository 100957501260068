export function sortByDate(
  a: Date,
  b: Date,
  {
    ascending = true,
  }: {
    ascending?: boolean;
  } = {}
): number {
  return ascending ? a.getTime() - b.getTime() : b.getTime() - a.getTime();
}

export function dateToUnix(date: Date): number {
  return parseInt((date.getTime() / 1000).toFixed(0), 10);
}

export function unixToDate(unix: number): Date {
  return new Date(unix * 1000);
}

export function serverToClientDate(date: string | number): Date {
  // ? If it's a number, transform if from unix date
  if (typeof date === 'number') return unixToDate(date);

  const parsed = parseInt(date, 10);
  // ? If it's a number (in string format), transform if from unix date
  if (/^\d+$/.test(date)) return unixToDate(parsed);
  // ? If it's a string, return it as a date
  else return new Date(date);
}

export function mostRecent(dates: Date[]): Date {
  return new Date(Math.max(...dates.map((date) => date.getTime())));
}

export function isToday(date: Date): boolean {
  return isLastDays(date, 0);
}

export function isLastDays(date: Date, days: number): boolean {
  const today = new Date();
  const lastDate = new Date(today.getTime() - days * 24 * 60 * 60 * 1000);
  lastDate.setHours(0, 0, 0, 0);
  return date >= lastDate && date <= today;
}

export function addTime(
  date: Date,
  time: {
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
  }
): Date {
  return new Date(
    date.getTime() +
      (time.hours ?? 0) * 60 * 60 * 1000 +
      (time.minutes ?? 0) * 60 * 1000 +
      (time.seconds ?? 0) * 1000 +
      (time.milliseconds ?? 0)
  );
}
