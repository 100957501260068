import { FileExtension } from '@enums/fileExtensions.enum';
import { MessageType } from '@enums/messageType.enum';

import { FilesService } from '@services/files/files.service';

import { BaseFileMessage, IBaseFileMessage } from './base/FileMessage.base';

// ? Defined in the backend (config.adapter.ts)
enum AllowedExtensions {
  MP3 = FileExtension.MP3,
  M4A = FileExtension.M4A,
  GPP3 = FileExtension.GPP3,
  OGG = FileExtension.OGG,
  WEBM = FileExtension.WEBM,
}
export const AllowedMIMEs = Object.values(AllowedExtensions).map((ext) =>
  FilesService.mime(ext)
);

export interface IAudioMessage extends IBaseFileMessage {
  type: MessageType.AUDIO;
  message?: never;

  extension: AllowedExtensions;
  duration: number;
  // size: number;
  thumbnail?: string; // ? If the audio is a voice message, it will not have a thumbnail, but if it is an audio file, it may have a thumbnail (album cover)
}

export class AudioMessage extends BaseFileMessage implements IAudioMessage {
  override readonly type: MessageType.AUDIO = MessageType.AUDIO;
  override readonly message!: never;

  override readonly extension!: AllowedExtensions;
  override readonly duration!: number;
  // override readonly size!: number;
  override readonly thumbnail?: string;

  constructor(
    message: Omit<IAudioMessage, 'type' | 'extension'> & { extension: string }
  ) {
    super({ ...message, type: MessageType.AUDIO });
    // Object.assign(this, message); // ? This would override class properties of the base message.
    this.extension = intoAllowedAudioExtensions(message.extension);
    this.duration = message.duration;
    this.thumbnail = message.thumbnail;
  }
}

function intoAllowedAudioExtensions(extension: string) {
  const allowed = Object.values(AllowedExtensions);
  if (!allowed.includes(extension as AllowedExtensions)) {
    throw new Error(`Extension ${extension} is not allowed`);
  }
  return extension as AllowedExtensions;
}
