interface IMessageStatus {
  unreceived: number[];
  received: IUserDate[];
  readed: IUserDate[];
}

interface IUserDate {
  id: number;
  date: Date;
}

export class MessageStatus implements IMessageStatus {
  readonly unreceived!: number[];
  readonly received!: IUserDate[];
  readonly readed!: IUserDate[];

  constructor(status: IMessageStatus) {
    Object.assign(this, status);
  }

  hasBeenReceived(id?: number): boolean {
    if (!id) return this.unreceived.length === 0 && this.received.length > 0;
    return this.received.some((userDate) => userDate.id === id);
  }

  hasBeenReaded(id?: number): boolean {
    if (!id) return this.readed.length > 0;
    return this.readed.some((userDate) => userDate.id === id);
  }
}
