import { MessageType } from '@enums/messageType.enum';

import { BaseMessage, IBaseMessage } from './base/Message.base';

interface ICoordinates {
  type: MessageType.LOCATION;

  latitude: number;
  longitude: number;
}

export interface ILocationMessage extends IBaseMessage {
  type: MessageType.LOCATION;
  message: never;

  coordinates: ICoordinates;
}

export class LocationMessage extends BaseMessage implements ILocationMessage {
  override readonly type: MessageType.LOCATION = MessageType.LOCATION;
  override readonly message!: never;

  readonly coordinates!: ICoordinates;

  constructor(message: Omit<ILocationMessage, 'type'>) {
    super({ ...message, type: MessageType.LOCATION });
    // Object.assign(this, message); // ? This would override class properties of the base message.
    this.coordinates = message.coordinates;
  }
}
