import { RoomType } from '@enums/roomType.enum';

import { BaseRoom, Dates, IBaseRoom } from './rooms/base/Room.base';

interface ExtendedDates<T = Date> extends Dates<T> {
  pictureChanged?: T; // TODO: Determine if we really need this or not
  lastEvent?: T;
}

export interface IDiscover extends IBaseRoom {
  idDiscover: number; // TODO: Delete once the server properly implements discovers with their exclusive ids

  type: RoomType.Discover;
  dates: ExtendedDates<Date>;
  index?: number;
  picture?: Blob;

  additionalInfo?: string;
  color?: string;

  vinculated: boolean; // ? This lets us know if the authenticated user is already in the room or not
}

export class Discover extends BaseRoom implements IDiscover {
  readonly idDiscover!: number; // TODO: Delete once the server properly implements discovers with their exclusive ids

  override readonly type: RoomType.Discover = RoomType.Discover;
  override readonly dates!: ExtendedDates<Date>;
  readonly index?: number;

  readonly additionalInfo?: string;
  readonly color?: string;

  readonly vinculated!: boolean;

  constructor(discover: Omit<IDiscover, 'type'>) {
    super({ ...discover, type: RoomType.Discover });
    Object.assign(this, discover);
  }
}

export function instanceOfDiscover(generic?: any): generic is Discover {
  return generic instanceof Discover && generic.type === RoomType.Discover;
}
