<div
  class="relative h-full w-full"
  [ngClass]="{
    'flex items-center justify-center bg-grey-light text-grey':
      picture$() !== 'loaded',
  }"
>
  @switch (picture$()) {
    @case ('pending') {
      <i class="fad fa-user text-2xl"></i>
    }
    @case ('loading') {
      <i class="fad fa-spinner-third fa-spin text-2xl"></i>
    }
    @case ('loaded') {
      @if (!inline() || file()) {
        <img
          [src]="src$()"
          [alt]="alt$()"
          class="absolute h-full w-full object-cover"
        />
      }
    }
    @case ('error') {
      <i class="fad fa-exclamation-triangle text-red text-2xl"></i>
    }
  }

  <!--
  TODO 'fill' mode generates a console warning despite parent height is >0 and position is defined as reltive
  ?  NG02952: The NgOptimizedImage directive (activated on an <img> element with the `ngSrc="url()"`) has detected that the height of the fill-mode image is zero. This is likely because the containing element does not have the CSS 'position' property set to one of the following: "relative", "fixed", or "absolute". To fix this problem, make sure the container element has the CSS 'position' property defined and the height of the element is not zero.
  -->
  @if (!file() && inline() && id()) {
    <img
      [ngSrc]="url(this.id()!)"
      class="object-cover"
      [ngClass]="{ 'opacity-0': picture$() !== 'loaded' }"
      [alt]="alt$()"
      fill
      (load)="onLoaded()"
      (error)="onError()"
    />
  }
</div>
