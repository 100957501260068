export enum MessageType {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  FILE = 'file',
  LOCATION = 'location',
  CONTACT = 'contact',
  POLL = 'poll',
  /*
  STICKER = 'sticker',
  LINK = 'link',
  EMOJI = 'emoji',
  GIF = 'gif',
  VOICE = 'voice',
  */
}
