import RoomMemberDTO from './room-member.dto';
import { RoomMember } from '@models/rooms/room-member.class';

export class RoomMemberMapper {
  static fromDTO(dto: RoomMemberDTO): RoomMember {
    /*
    return new User({
      idUser: dto.idUser,
      name: dto.name,
      surname: dto.surname,
      authorized: dto.authorized,
    });
    */
    return new RoomMember({
      id: dto.idUser,
      administrator: dto.authorized === 1,
    });
  }

  /*
  static toDTO(entity) {
    return {
      idUser: entity.idUser,
      name: entity.name,
      surname: entity.surname,
      authorized: entity.authorized,
    };
  }
  */
}
