import { BaseMessage, IBaseMessage } from './Message.base';

export interface IBaseFileMessage extends IBaseMessage {
  message?: string;
  file?: Blob;

  extension: string;
  duration?: number; // ? In seconds
  // size?: number;
  url?: string;
  thumbnail?: string; // ? Base64 string of the thumbnail image
  /*
  preview?: {
    url: string;
    width: number;
    height: number;
  }
  */
}

export class BaseFileMessage extends BaseMessage implements IBaseFileMessage {
  override readonly message?: string;
  readonly file!: Blob;

  readonly extension!: string;
  readonly duration?: number;
  // readonly size?: number;
  readonly url?: string;
  readonly thumbnail?: string;

  constructor(msg: IBaseFileMessage) {
    super(msg);
    // Object.assign(this, message); // ? This would override class properties of the base message.
    this.message = msg.message;
    // this.file = msg.file;

    this.extension = msg.extension;
    this.duration = msg.duration;
    // this.size = msg.size
    this.url = msg.url;
    this.thumbnail = msg.thumbnail;
  }

  download(): void {
    const a = document.createElement('a');
    a.href = this.url || '';
    // a.download = this.file.name || ''; // ? If the browser does not support the download attribute, the file will be opened in a new tab
    a.click();
  }
}
