import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { Component, Inject } from '@angular/core';

import {
  ToastOptions as HotToastOptions,
  HotToastRef,
} from '@ngxpert/hot-toast';

import { SemanticLevel } from '@models/semantic.enum';

import {
  ToastActionButton,
  ToastOptions,
  ToastService,
} from '@services/toast/toast.service';

import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgClass, NgStyle, ProgressBarComponent],
})
export class NotificationComponent {
  id!: string;
  type!: SemanticLevel;
  message!: string;
  options?: Partial<HotToastOptions<any>>;
  actions?: ToastActionButton[];

  progressBar?: 'top' | 'bottom' | 'none';
  progress: number = 0;

  constructor(
    @Inject(HotToastRef)
    notification: HotToastRef<{
      id: string;
      type: SemanticLevel;
      message: string;
      options?: ToastOptions;
      actions?: ToastActionButton[];
      progressBar?: 'top' | 'bottom' | 'none';
    }>,
    private toastService: ToastService
  ) {
    Object.assign(this, notification.data);
  }

  close() {
    this.toastService.close(this.id);
  }
}
