import { computed, inject, isDevMode } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { interval } from 'rxjs';

import {
  getState,
  patchState,
  signalStore,
  watchState,
  withComputed,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import {
  addEntities,
  addEntity,
  EntityState,
  removeAllEntities,
  removeEntities,
  removeEntity,
  setAllEntities,
  setEntities,
  setEntity,
  updateAllEntities,
  updateEntities,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';

import { instanceToPlain, plainToInstance } from 'class-transformer';

import { Discover, IDiscover } from '@models/discover.class';

import { StorageService } from '@services/storage/storage.service';

const TAG = 'Discovers Store';

type Entity = IDiscover;
type ID = Entity['id'];
type Class = Discover;
type R<T> = T extends undefined
  ? Class[]
  : T extends ID[]
    ? Class[]
    : Class | undefined;

export type DiscoversState = EntityState<Entity>;
export type DiscoversStore = InstanceType<typeof DiscoversStore>;

export const DiscoversStore = signalStore(
  // withState(initialDiscoversState), // ! If we initialize the state here, it will overwrite the state from the storage on store initialization
  withEntities<Entity>(),
  withComputed(({ entities }) => ({
    unreadChats: computed(
      () => entities().filter((entity) => entity.unread).length
    ),
    unreadMessages: computed(() =>
      entities().reduce((acc, entity) => acc + (entity.unread || 0), 0)
    ),
    vinculated: computed(() =>
      entities()
        .filter((entity) => entity.vinculated)
        .map((entity) => entity as Class)
    ),
  })),
  withMethods((store, storageService = inject(StorageService)) => ({
    // Entity methods
    // ? We are making this private because we don't want to expose the entityMap directly
    get<T extends undefined | ID | ID[]>(ids?: T): R<T> {
      // const map = getState(store).entityMap;
      const map = store.entityMap();

      if (!ids)
        return <R<T>>(
          store.entities().map((entity) => plainToInstance(Discover, entity))
        );
      else if (Array.isArray(ids)) {
        return <R<T>>ids
          .map((id) => map[id])
          .filter((entity): entity is Entity => !!entity)
          .map((entity) => plainToInstance(Discover, entity));
      } else {
        const entity = map[ids as ID];
        return <R<T>>(entity ? plainToInstance(Discover, entity) : undefined);
      }
    },

    all() {
      return this.get(undefined);
    },

    find<T extends boolean>(
      predicate: (entity: Entity) => boolean,
      { multiple }: { multiple: T }
    ): T extends true ? Class[] : Class | undefined {
      const entities = store
        .entities()
        .map((entity) => plainToInstance(Discover, entity));

      if (multiple) {
        return entities.filter(predicate) as T extends true ? Class[] : never;
      } else {
        return (entities.find(predicate) ?? undefined) as T extends true
          ? never
          : Class | undefined;
      }
    },

    add(
      ent: Entity | Entity[],
      { override, update }: { override?: boolean; update?: boolean } = {
        override: false,
        update: false,
      }
    ): void {
      let entities: Entity | Entity[] = ent;
      /*
        if (Array.isArray(ent))
          entities = ent.map((entity) =>
            entity.id === entity.idDiscover
              ? entity
              : new Discover({
                  ...entity,
                  idDiscover:
                    this.find((e) => e.id === entity.id, {
                      multiple: false,
                    })?.idDiscover || entity.id,
                })
          );
        else
          entities =
            ent.id === ent.idDiscover
              ? new Discover({
                  ...ent,
                  idDiscover:
                    this.find((e) => e.id === ent.id, {
                      multiple: false,
                    })?.idDiscover || ent.id,
                })
              : ent;
        */
      if (override) {
        if (Array.isArray(entities)) patchState(store, setEntities(entities));
        else patchState(store, setEntity(entities));
      } else {
        if (update) {
          if (Array.isArray(entities)) {
            const map = new Map<ID, Entity>();
            entities.forEach((entity) => map.set(entity.id, entity));
            this.update(
              entities.map((entity) => entity.id),
              (entity) => map.get(entity.id) ?? entity
            );
          } else this.update(entities.id, () => entities);
        }

        // ? AddEntities will not override the entities, it will only add the missing ones
        if (Array.isArray(entities)) patchState(store, addEntities(entities));
        else patchState(store, addEntity(entities));
      }
    },

    update(ids: ID | ID[], changes: (entity: Entity) => Partial<Entity>): void {
      if (Array.isArray(ids))
        patchState(store, updateEntities({ ids, changes }));
      else patchState(store, updateEntity({ id: ids, changes }));
    },

    remove(ids: ID | ID[]): void {
      if (Array.isArray(ids)) patchState(store, removeEntities(ids));
      else patchState(store, removeEntity(ids));
    },

    // Entity collection methods
    replaceCollection(entities: Entity[]): void {
      patchState(store, setAllEntities(entities));
    },

    updateCollection(changes: (entity: Entity) => Partial<Entity>): void {
      patchState(store, updateAllEntities(changes));
    },

    deleteCollection(): void {
      patchState(store, removeAllEntities());
    },

    reset(): void {
      this.deleteCollection();
    },

    // Hydration methods
    hydrate: (): void => {
      console.debug(`[${TAG}] Starting hydration...`);
      if (storageService.check(TAG)) {
        const stored = storageService.get<DiscoversState>(TAG, (stored) => ({
          ids: stored.ids,
          entityMap: Object.fromEntries(
            Object.entries(stored.entityMap).map(([key, value]) => [
              key,
              plainToInstance(Discover, value),
            ])
          ),
        }));
        console.debug(`[${TAG}] Hydrating...`, stored);
        if (stored !== null)
          // ? Only restore some properties of the stored state
          patchState(store, (state) => ({ ...state, ...stored }));
      } else {
        console.debug(`[${TAG}] No previous state found`);
      }
    },
    persist(): void {
      console.debug(`[${TAG}] Persisting state...`);
      storageService.set(TAG, instanceToPlain(getState(store)));
    },

    // Custom methods
  })),
  withHooks({
    onInit: (store) => {
      if (isDevMode()) console.debug(`[${TAG}] Store initialized`);

      // ? Effects
      // https://next.ngrx.io/guide/signals/signal-store/state-tracking#using-getstate-and-effect
      // effect(() => {}, {});

      store.hydrate();

      if (isDevMode())
        watchState(store, (state) =>
          console.debug(`[${TAG}] State updated:`, state)
        );

      // ? Instead of watching the state and persisting it everytime it changes, we persist it every x minutes
      const SAVE_INTERVAL_MINUTES = 5; // Set the interval time in minutes
      interval(SAVE_INTERVAL_MINUTES * 60 * 1000)
        .pipe(takeUntilDestroyed())
        .subscribe(() => {
          if (isDevMode())
            console.debug(`[${TAG}] Persisting state at interval`);
          store.persist();
        });
    },
    onDestroy: (store) => {
      if (isDevMode())
        console.debug(`[${TAG}] Store destroyed`, getState(store));
      store.persist();
    },
  })
);
