import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable, tap } from 'rxjs';

import { configuration } from '@configuration/configuration';

import { dateToUnix } from '@utils/time.utils';

import { OTO } from '@models/oto.class';
import GetOTOsResponseDTO from '@services/api/otos/models/get-otos.response.dto';
import { OTOMapper } from '@services/api/otos/models/oto.mapper';

import { OTOsStore } from '@state/stores/otos.store';
import { UsersStore } from '@state/stores/users.store';

@Injectable({
  providedIn: 'root',
})
export class OTOsService {
  private url: string;

  constructor(
    private http: HttpClient,
    private readonly usersStore: UsersStore,
    private readonly otosStore: OTOsStore
  ) {
    this.url = configuration.apiUrl;
  }

  getOTOs(options?: { date?: Date }): Observable<OTO[]> {
    let queryParams = new HttpParams();
    // Date in unix format
    if (options?.date)
      queryParams = queryParams.set(
        'changes',
        dateToUnix(options.date).toString()
      );

    return this.http
      .get<GetOTOsResponseDTO>(`${this.url}/users/me/onetoone`, {
        params: queryParams,
      })
      .pipe(
        map((response) =>
          response.rooms.map((room) => OTOMapper.fromDTO(room))
        ),
        tap((otos) => this.otosStore.add(otos, { override: true }))
      );
  }

  /*
  getOTOById(id: number): Observable<OTO> {
    return this.http.get<OTO>(`${this.url}/otos/${id}`);
  }
  */

  /*
  getOTOPicture(id: number): Observable<Blob> {
    return this.http.get(`${this.url}/otos/${id}/imageprofile`, {
      responseType: 'blob',
    });
  }
  */

  createOTO(id: number): Observable<void> {
    return this.http.post<void>(`${this.url}/users/${id}/openchat`, {}).pipe(
      tap(() => {
        const oto = new OTO({
          id,
          name: this.usersStore.get(id)?.name || 'OTO',
          dates: {
            created: new Date(),
          },
        });
        this.otosStore.add(oto);
      })
    );
  }

  leaveOTO(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.url}/users/${id}/onetoone`)
      .pipe(tap(() => this.otosStore.remove(id)));
  }
}
