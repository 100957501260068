import { Observable, of } from 'rxjs';

import { Exclude } from 'class-transformer';

import { RoomType } from '@enums/roomType.enum';

import { Draft } from '@models/messages/draft.interface';

import { FilesService } from '@services/files/files.service';

export interface Dates<T = Date> {
  created?: T; // TODO: Revise why not all responses from server include this
  modified?: T;
}

export interface IBaseRoom {
  id: number;
  type: RoomType;
  name: string;
  description?: string;
  dates: Dates<Date | string>;
  picture?: Blob;

  bidirectional: boolean;

  members?: number[];
  administrators?: number[];

  unread?: number;
  draft?: Draft;
}

export class BaseRoom implements IBaseRoom {
  readonly id!: number;
  readonly type!: RoomType;
  readonly name!: string;
  readonly description!: string;
  dates!: Dates<Date>;

  @Exclude() // ? This is to avoid Blob serialization
  readonly picture?: Blob;

  readonly bidirectional!: boolean;

  members?: number[];
  administrators?: number[];

  readonly unread?: number; // ? This is the number of unread messages
  readonly draft?: Draft; // ? This is the message we are writing in the chat input

  constructor(room: IBaseRoom) {
    Object.assign(this, {
      ...room,
      dates: room.dates && {
        created: room.dates.created ? new Date(room.dates.created) : undefined,
        modified: room.dates.modified
          ? new Date(room.dates.modified)
          : undefined,
      },
    });
  }

  get pictureURL$(): Observable<string> {
    if (this.picture) return FilesService.blobToURL(this.picture);
    else return of('');
  }
}
