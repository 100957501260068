// ? This is a service that will allow us to understand the network status of the user (online/offline) and of the server (connected/disconnected)
import { computed, Injectable, signal } from '@angular/core';

import { fromEvent } from 'rxjs';

import { SemanticLevel } from '@models/semantic.enum';

import { ToastService } from '@services/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  browser$ = signal<boolean>(false);
  server$ = signal<boolean>(false);
  connected$ = computed(() => this.browser$() && this.server$());

  constructor(private toastService: ToastService) {
    this.browser$.set(this.browser());

    fromEvent(window, 'online').subscribe(() => {
      // console.debug('[NetworkService] Browser is online');
      this.browser$.set(true);
      this.toastService.close('offline');
    });
    fromEvent(window, 'offline').subscribe(() => {
      // console.debug('[NetworkService] Browser is offline');
      this.browser$.set(false);
      this.toastService.custom('You are offline', {
        id: 'offline',
        type: SemanticLevel.Warning,
        position: 'bottom-center',
        dismissible: false,
        // persist: { enabled: true },
      });
    });
  }

  browser(): boolean {
    return navigator.onLine;
  }
}
