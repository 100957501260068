import { MessageType } from '@enums/messageType.enum';
import { ModificationState } from '@enums/modificationState.enum';

import { MessageStatus } from '@models/message-status.class';

/*
export type MessageIDs<T> = { sender: number } & (T extends RoomType.OTO
  ? { receiver: number }
  : T extends RoomType.Group
  ? { group: number }
  : T extends RoomType.Discover
  ? { discover: number }
  : never);
*/

/*
export type MessageIDs =
  | {
      sender: number;
      receiver: number;
    }
  | {
      sender: number;
      room: number;
    }
  | {
      sender: number;
      discover: number;
    };
*/

export interface MessageIDs {
  server?: string; // ? Identifier of the object in the server database
  sender: number;
  receiver?: number;
  room?: number;
  // discover?: number;
  replyId?: string; // ? The id of the message that is being replied to
}

interface MessageDates {
  sentDate: Date;
  readDate?: Date;
  modifiedDate?: Date;
  deletedDate?: Date;
}

export interface RepliedMessage {
  id: string;
  message: string;
  file: string;
  sender: number;
  date: Date;
  state: ModificationState;
}

export interface IBaseMessage {
  id: string; // ? UUID of the message (genereated for the local databases in the apps, when sending messages)
  type: MessageType;
  message?: string;
  ids: MessageIDs;
  dates: MessageDates;
  synchronized: boolean;
  sentAttempts?: number;
  status?: MessageStatus;
  state?: ModificationState;
  reply?: RepliedMessage;
}

export class BaseMessage implements IBaseMessage {
  readonly id!: string;
  readonly type!: MessageType;
  readonly message?: string;
  readonly ids!: MessageIDs;
  readonly dates!: MessageDates;
  readonly synchronized!: boolean;
  readonly sentAttempts!: number;
  readonly state!: ModificationState;
  readonly status?: MessageStatus;
  readonly reply?: RepliedMessage;

  constructor(msg: IBaseMessage) {
    Object.assign(this, msg);
    if (msg.status) this.status = new MessageStatus(msg.status);
  }

  // ? Methods
  isOwnMessage(senderId: number) {
    return this.ids.sender === senderId;
  }
}
