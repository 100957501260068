import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';

import { configuration } from '@configuration/configuration';

import { dateToUnix, serverToClientDate } from '@utils/time.utils';

import { RoomType } from '@enums/roomType.enum';

import { DiscoverMapper } from './models/discover.mapper';
import GetDiscoversResponseDTO from './models/get-discovers.response.dto';
import JoinDiscoverResponseDTO from './models/join-discover.response.dto';
import { Discover } from '@models/discover.class';
import DiscoversChanges from '@models/discovers-changes.interface';
import { RoomMembers } from '@models/rooms/room-members.class';
import { GenericRoomMapper } from '@services/api/rooms/models/generic-room.mapper';
import GetRoomResponseDTO from '@services/api/rooms/models/get-room.response.dto';

import { DiscoversStore } from '@state/stores/discovers.store';
import { RoomsStore } from '@state/stores/rooms.store';
import { UIStore } from '@state/stores/ui.store';

import { RoomsService } from '../rooms/rooms.service';
import { AuthTokenService } from '@services/tokens/auth-token.service';

@Injectable({
  providedIn: 'root',
})
export class DiscoversService {
  private url: string;

  constructor(
    private http: HttpClient,
    private readonly discoversStore: DiscoversStore,
    private readonly roomsStore: RoomsStore,
    private readonly uiStore: UIStore,
    private readonly tokenService: AuthTokenService,
    private readonly roomsService: RoomsService
  ) {
    this.url = configuration.apiUrl;
  }

  // TODO: Switch to discover-only endpoint when server endpoint is updated
  getDiscoverById(id: number, idDiscover?: number): Observable<Discover> {
    return this.http.get<GetRoomResponseDTO>(`${this.url}/rooms/${id}`).pipe(
      map((discover) => ({
        ...discover,
        idDiscover: idDiscover || this.discoversStore.get(id)?.idDiscover,
      })),
      map((discover) => GenericRoomMapper.fromDTO(discover) as Discover),
      tap((discover) =>
        this.discoversStore.add(discover as Discover, { override: true })
      ),
      mergeMap((discover) =>
        this.getMembers(discover.id).pipe(
          map((members) => {
            discover.members = discover.members;
            discover.administrators = discover.administrators;
            return discover;
          })
        )
      )
    );
  }

  getDiscovers(options?: { date?: Date }): Observable<DiscoversChanges> {
    let queryParams = new HttpParams();
    // Date in unix format
    if (options?.date)
      queryParams = queryParams.set(
        'changes',
        dateToUnix(options.date).toString()
      );

    return this.http
      .get<GetDiscoversResponseDTO>(`${this.url}/discovers/list`, {
        params: queryParams,
      })
      .pipe(
        map((response) => {
          return {
            discovers: response.all.map((discover) =>
              DiscoverMapper.fromDTO(discover)
            ),
            deleted: response.deleted,
          };
        }),
        tap((changes) => {
          this.discoversStore.add(changes.discovers, { override: true });
          this.discoversStore.remove(changes.deleted);
        }),
        mergeMap((changes) =>
          forkJoin(
            changes.discovers.map((discover) => {
              // TODO: Currently we need to be in a discover to get the members, this should be changed in the BackEnd in the future.
              if (discover.vinculated)
                // TODO: We need to be in a discover to get the discover by its room id, and know if it's bidirectional or not
                return this.getDiscoverById(discover.id).pipe(
                  switchMap((discover) =>
                    this.getMembers(discover.id).pipe(
                      map((members) => {
                        discover.members = members.members;
                        discover.administrators = members.administrators;
                        return discover;
                      })
                    )
                  )
                );
              else return of(discover);
            })
          ).pipe(
            map((discovers) => ({
              ...changes,
              discovers,
            }))
          )
        )
      );
  }

  /*
  // ? Unnecessary, use getDiscovers() instead with the date option
  getDiscoverChanges(date: Date): Observable<GetDiscoversResponseDTO> {
    return this.http.get<GetDiscoversResponseDTO>(`${this.url}/discovers/list`);
  }
  */

  /*
  getDiscoverById(id: number): Observable<Discover> {
    return this.http.get<Discover>(`${this.url}/discovers/${id}`);
  }
  */

  getDiscoverPicture(id: number): Observable<Blob> {
    return this.http.get(`${this.url}/rooms/${id}/imageprofile`, {
      responseType: 'blob',
    });
  }

  getDiscoverPictureURL(id: number, includeToken?: boolean): string {
    const params: HttpParams = new HttpParams({
      fromObject: {
        authorization: (includeToken && this.tokenService.token) || '',
      },
    });
    const httpRequest = new HttpRequest(
      'GET',
      `${configuration.apiUrl}/rooms/${id}/imageprofile`,
      {
        params,
      }
    );
    return httpRequest.urlWithParams;
  }

  getDiscoverBanner(id: number): Observable<Blob> {
    return this.http.get(`${this.url}/discovers/${id}/imageprofile`, {
      responseType: 'blob',
    });
  }

  getDiscoverBannerURL(id: number, includeToken?: boolean): string {
    const params: HttpParams = new HttpParams({
      fromObject: {
        authorization: (includeToken && this.tokenService.token) || '',
      },
    });
    const httpRequest = new HttpRequest(
      'GET',
      `${configuration.apiUrl}/discovers/${id}/imageprofile`,
      {
        params,
      }
    );
    return httpRequest.urlWithParams;
  }

  joinDiscover(discover: Discover): Observable<Discover> {
    return this.http
      .post<JoinDiscoverResponseDTO>(
        `${this.url}/discovers/${discover.idDiscover}/join`,
        {}
      )
      .pipe(
        map((response) => {
          return new Discover({
            ...discover,
            name: response.name,
            description: response.description,
            dates: {
              modified: serverToClientDate(response.lastModification),
              pictureChanged: serverToClientDate(response.lastImageChange),
              lastEvent: serverToClientDate(response.lastActivity),
            },
            // picture: response.image,
            vinculated: true,
          });
        })
      )
      .pipe(
        tap(() => {
          this.discoversStore.update(discover.id, () => ({ vinculated: true }));
          // this.roomsStore.add(id);
        })
      );
  }

  leaveDiscover(discover: Discover): Observable<void> {
    return this.http
      .delete<void>(`${this.url}/discovers/${discover.idDiscover}/quit`)
      .pipe(
        tap(() => {
          this.discoversStore.update(discover.id, () => ({
            vinculated: false,
          }));
          // this.roomsStore.remove(id);

          if (
            this.uiStore.chat()?.id === discover.id &&
            this.uiStore.chat()?.type === RoomType.Discover
          )
            this.uiStore.reset('chat');
        })
      );
  }

  getMembers(id: number): Observable<RoomMembers> {
    return this.roomsService.getMembers(id).pipe(
      tap((members) =>
        this.discoversStore.update(id, () => ({
          members: members.members,
          administrators: members.administrators,
        }))
      )
    );
  }
}
