import { RoomType } from '@enums/roomType.enum';

import { BaseRoom, Dates, IBaseRoom } from './rooms/base/Room.base';

interface ExtendedDates<T = Date> extends Dates<T> {
  lastEvent?: T;
}

export interface IOTO extends IBaseRoom {
  type: RoomType.OTO;
  dates: ExtendedDates<Date>;
  members?: never;
  administrators?: never;
}

export class OTO extends BaseRoom implements IOTO {
  override readonly type!: RoomType.OTO;
  override readonly dates!: ExtendedDates<Date>;
  override readonly members?: never;
  override readonly administrators?: never;

  constructor(oto: Omit<IOTO, 'type' | 'bidirectional'>) {
    super({ ...oto, type: RoomType.OTO, bidirectional: true });
    Object.assign(this, oto);
  }
}

export function instanceOfOTO(generic?: any): generic is OTO {
  return generic instanceof OTO && generic.type === RoomType.OTO;
}
