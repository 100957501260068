import { computed, Injectable, signal } from '@angular/core';
import { SwPush } from '@angular/service-worker';

import { catchError, from, map, Observable, of, switchMap, tap } from 'rxjs';

import { SemanticLevel } from '@models/semantic.enum';

import { ToastService } from '@services/toast/toast.service';

export const NOTIFICATIONS_SUPPORTED =
  'Notification' in window &&
  'PushManager' in window &&
  'serviceWorker' in navigator;

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  supported$ = signal<boolean>(NOTIFICATIONS_SUPPORTED);
  status$ = signal<NotificationPermission>('default');
  granted$ = computed(
    () =>
      this.status$() === 'granted' ||
      (NOTIFICATIONS_SUPPORTED && Notification.permission === 'granted')
  );

  constructor(
    private swPush: SwPush,
    private toastService: ToastService
  ) {
    /*
    from(navigator.serviceWorker.ready)
      .pipe(
        tap(() => console.log('Service Worker ready')),
        map((registration) => {
          const messageHandler = (event: MessageEvent) => {
            console.log('Badge Icon:', event.data);
          };
          navigator.serviceWorker.addEventListener('message', messageHandler);
          registration.active?.postMessage({
            action: 'badge-content',
            content: 5,
          });
          return registration;
        }),
        catchError((error) => {
          console.error('Error setting up message listener:', error);
          return of(undefined);
        })
      )
      .subscribe();
      */

    // ? Listen for Push Notifications
    this.swPush.messages
      .pipe(
        map((message) => message as { notification: Notification }),
        tap((message) => console.log('SW Message:', message))
        /*
        // ! We can't modify the notification content through Angular's service worker
        map((message: any) => ({
          ...message,
          notification: {
            ...message.notification,
            title: '[BadgeService]' + message.notification.title,
            body: '[BadgeService]' + message.notification.body,
          },
        })),
        tap((message) => console.log('Updated SW Message:', message)),
        switchMap((message) => of(message))
        */
      )
      .subscribe((message) => {
        const data = message.notification?.data;
        // this.content = data?.badge || (this.content || 0) + 1;
        // console.log('BadgeService SW Message:', this.content);
        (navigator as any).setAppBadge(1);
        // this.show(message.notification.title, message.notification);
        // this.interceptNotification(message.notification);
      });

    // ? Listen for user interaction with notifications
    this.swPush.notificationClicks
      // .messages
      .pipe(tap((data) => console.log('A notification was clicked!', data)))
      .subscribe((data) => {
        console.log('Notification clicked:', data);

        // ? Clear all notifications
        this.clearNotifications().subscribe();
      });
  }

  request(): Observable<NotificationPermission> {
    console.log('[Notifications Service] Requesting notifications permission');
    if (this.supported$()) {
      return from(Promise.resolve(Notification.requestPermission())).pipe(
        catchError((error) => {
          console.error('[Notifications Service] Denied permission', error);
          // throw error;
          return of('denied' as NotificationPermission);
        }),
        tap((status) =>
          console.log('[Notifications Service] Permission granted')
        ),
        map((status) => {
          this.status$.set(status);
          return status;
        })
      );
    } else {
      console.error('[Notifications Service] Notifications not supported');
      return of('denied' as NotificationPermission);
    }
  }

  show(title: string, options?: NotificationOptions): void {
    if (this.granted$()) new Notification(title, options);
    else if (!this.supported$())
      throw new Error('Notifications not supported by your browser');
  }

  test(): void {
    this.toastService.custom(
      `Permissions already granted. Sending a Push to test them...`,
      {
        id: 'notifications-granted',
        type: SemanticLevel.Info,
        position: 'top-center',
        dismissible: true,
        duration: 5000,
      }
    );
    this.show('Push Notification', {
      body: 'This is a test notification',
    });
    this.toastService.custom(
      `     If you don't see them, check your OS settings (and verify your Do not disturb configuration)...`,
      {
        id: 'notifications-granted',
        type: SemanticLevel.Info,
        position: 'top-center',
        dismissible: true,
        duration: 5000,
      }
    );
  }

  private interceptNotification(notification: Notification) {
    if (Notification.permission === 'granted') {
      navigator.serviceWorker.getRegistration().then((registration) => {
        registration?.showNotification(notification.title, notification);
      });
    }
  }

  private getNotifications(): Observable<Notification[]> {
    return from(navigator.serviceWorker.getRegistration()).pipe(
      switchMap((registration) => {
        if (!registration || !registration.active) return of([]);
        return from(registration.getNotifications());
      })
    );
  }

  private clearNotifications(filter?: () => boolean): Observable<void> {
    return this.getNotifications().pipe(
      map((notifications) => {
        if (filter) notifications = notifications.filter(filter);
        return notifications;
      }),
      tap((notifications) =>
        notifications.forEach((notification) => notification.close())
      ),
      tap(() => console.log('Notifications cleared')),
      map(() => undefined)
    );
  }
}
