import { AsyncPipe, NgClass, NgOptimizedImage } from '@angular/common';
import {
  Component,
  computed,
  effect,
  HostBinding,
  input,
  signal,
} from '@angular/core';

import {
  asyncScheduler,
  filter,
  Observable,
  observeOn,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { UsersStore } from '@state/stores/users.store';

import { UsersService } from '@services/api/users/users.service';
import { FilesService } from '@services/files/files.service';

@Component({
  selector: 'app-user-picture',
  templateUrl: './user-picture.component.html',
  styleUrls: ['./user-picture.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgOptimizedImage, NgClass],
})
export class UserPictureComponent {
  private destroy$ = new Subject<void>();

  // Host bindings
  @HostBinding('class') class =
    'h-10 w-10 flex-shrink-0 rounded-full overflow-hidden';

  // Inputs & Outputs
  id = input<number>();
  file = input<Blob>();
  inline = input<boolean>(true);

  // Properties
  picture$ = signal<'pending' | 'loading' | 'loaded' | 'error'>('pending');
  user$ = computed(() =>
    this.id() ? this.usersStore.get(this.id()!) : undefined
  );
  src$ = signal<string | undefined>(undefined);
  alt$ = computed(() => `Profile picture of user ${this.id()}`); // TODO: Replace id with user name

  // Lifecycle hooks
  constructor(
    private readonly usersService: UsersService,
    private readonly usersStore: UsersStore
  ) {
    effect(
      () => {
        if (this.id())
          of(true)
            .pipe(
              filter(() => !this.inline() && this.picture$() === 'pending'),
              tap(() => this.picture$.set('loading')),
              takeUntil(this.destroy$),
              observeOn(asyncScheduler),
              switchMap(() =>
                this.blob(this.id()!).pipe(
                  takeUntil(this.destroy$),
                  observeOn(asyncScheduler),
                  switchMap((blob) =>
                    FilesService.blobToURL<string>(blob).pipe(
                      takeUntil(this.destroy$),
                      observeOn(asyncScheduler)
                    )
                  )
                )
              )
            )
            .subscribe({
              next: (url) => {
                this.src$.set(url);
                this.picture$.set('loaded');
              },
              error: () => this.picture$.set('error'),
            });
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        if (this.file()) {
          FilesService.blobToURL<string>(this.file()!)
            .pipe(takeUntil(this.destroy$), observeOn(asyncScheduler))
            .subscribe({
              next: (url) => {
                this.src$.set(url);
                this.picture$.set('loaded');
              },
              error: () => this.picture$.set('error'),
            });
        }
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        if (this.user$()?.picture) {
          this.user$()!
            .pictureURL$.pipe(
              tap((url) => console.log('New picture url', url)),
              takeUntil(this.destroy$),
              observeOn(asyncScheduler)
            )
            .subscribe({
              next: (url) => {
                this.src$.set(url);
                this.picture$.set('loaded');
              },
              error: () => this.picture$.set('error'),
            });
        } else {
          console.log('No picture found for user', this.id());
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Methods
  private blob(id: number): Observable<Blob> {
    return this.usersService.getUserProfilePicture(id);
  }

  url(id: number): string {
    return this.usersService.getUserProfilePictureURL(id, true);
  }

  onLoaded(): void {
    this.picture$.set('loaded');
  }

  onError(): void {
    this.picture$.set('error');
  }
}
