import UserDTO from './user.dto';
import { User } from '@models/user.class';

export class UserMapper {
  static fromDTO(dto: UserDTO): User {
    return new User({
      id: dto.idUser,
      name: dto.name,
      surname: dto.surname,
      email: dto.email,
      bio: dto.additionalInfo,
    });
  }

  /*
  static toDTO(user: User): UserDTO {
    return {
      id: user.id,
      name: user.name,
      surname: user.surname,
      email: user.email,
      bio: user.bio,
      imageProfile: user.imageProfile,
      imageCover: user.imageCover,
      createdAt: user.createdAt.toISOString(),
      updatedAt: user.updatedAt.toISOString(),
    };
  }
  */
}
