interface IRoomMember {
  id: number;
  administrator: boolean;
}

export class RoomMember implements IRoomMember {
  id: number;
  administrator: boolean;

  constructor(roomMember: IRoomMember) {
    this.id = roomMember.id;
    this.administrator = roomMember.administrator;
  }
}
