import { OutgoingEvent } from '../zonetacts.events';

export class RoomMessageOutgoingEventDTO {
  private readonly event: OutgoingEvent.ROOM_MESSAGE;

  readonly idRoom!: number;
  readonly message!: string;
  readonly internalId!: string;
  readonly idMessageReply: string | undefined;

  constructor(message: {
    idRoom: number;
    message: string;
    internalId: string;
    idMessageReply: string | undefined;
  }) {
    this.event = OutgoingEvent.ROOM_MESSAGE;
    Object.assign(this, message);
  }
}
