import { FileExtension } from '@enums/fileExtensions.enum';
import { MessageType } from '@enums/messageType.enum';

import { FilesService } from '@services/files/files.service';

import { BaseFileMessage, IBaseFileMessage } from './base/FileMessage.base';

// ? Defined in the backend (config.adapter.ts)
enum AllowedExtensions {
  // ? Documents
  PDF = FileExtension.PDF,
}
export const AllowedMIMEs = Object.values(AllowedExtensions).map((ext) =>
  FilesService.mime(ext)
);

export interface IFileMessage extends IBaseFileMessage {
  type: MessageType.FILE;
  message?: never;

  extension: AllowedExtensions;
  // size: number;
  thumbnail?: string;
}

export class FileMessage extends BaseFileMessage implements IFileMessage {
  override readonly type: MessageType.FILE = MessageType.FILE;
  override readonly message?: never;

  override readonly extension!: AllowedExtensions;
  // override readonly size!: number;
  override readonly thumbnail?: string;

  constructor(
    message: Omit<IFileMessage, 'type' | 'extension'> & { extension: string }
  ) {
    super({ ...message, type: MessageType.FILE });
    // Object.assign(this, message); // ? This would override class properties of the base message.
    this.extension = intoAllowedFileExtensions(message.extension);
    this.thumbnail = message.thumbnail;
  }
}

function intoAllowedFileExtensions(extension: string) {
  const allowed = Object.values(AllowedExtensions);
  if (!allowed.includes(extension as AllowedExtensions)) {
    throw new Error(`Extension ${extension} is not allowed`);
  }
  return extension as AllowedExtensions;
}
