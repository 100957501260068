import { computed, Injectable } from '@angular/core';

import { RoomType } from '@enums/roomType.enum';

import { Chat } from '@models/chat.interface';
import { Draft } from '@models/messages/draft.interface';

import { DiscoversStore } from '@state/stores/discovers.store';
import { OTOsStore } from '@state/stores/otos.store';
import { RoomsStore } from '@state/stores/rooms.store';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  unreadMessages$ = computed(
    () =>
      this.otosStore.unreadMessages() +
      this.roomsStore.unreadMessages() +
      this.discoversStore.unreadMessages()
  );
  unreadChats$ = computed(
    () =>
      this.otosStore.unreadChats() +
      this.roomsStore.unreadChats() +
      this.discoversStore.unreadChats()
  );

  constructor(
    private readonly otosStore: OTOsStore,
    private readonly roomsStore: RoomsStore,
    private readonly discoversStore: DiscoversStore
  ) {}

  select(chat: Chat) {
    switch (chat.type) {
      case RoomType.OTO: {
        return this.otosStore.get(chat.id);
      }
      case RoomType.Group:
        return this.roomsStore.get(chat.id);
      case RoomType.Discover:
        return this.discoversStore.get(chat.id);
    }
  }

  draft(chat: Chat) {
    switch (chat.type) {
      case RoomType.OTO: {
        return this.otosStore.get(chat.id)?.draft;
      }
      case RoomType.Group:
        return this.roomsStore.get(chat.id)?.draft;
      case RoomType.Discover:
        return this.discoversStore.get(chat.id)?.draft;
    }
  }

  saveDraft(chat: Chat, draft: Draft) {
    switch (chat.type) {
      case RoomType.OTO: {
        return this.otosStore.update(chat.id, () => ({ draft }));
      }
      case RoomType.Group:
        return this.roomsStore.update(chat.id, () => ({ draft }));
      case RoomType.Discover:
        return this.discoversStore.update(chat.id, () => ({ draft }));
    }
  }
}
