// ? Badge API support: https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/How_to/Display_badge_on_app_icon#support_for_badges

import { effect, Injectable, signal } from '@angular/core';

import { ChatService } from '@services/api/chat.service';
import { NotificationsService } from '@services/notifications/notifications.service';

@Injectable({
  providedIn: 'root',
})
export class BadgeService {
  supported$ = signal<boolean>(false);

  // Lifecycle hooks
  constructor(
    private notificationsService: NotificationsService,
    private readonly chatService: ChatService
  ) {
    this.supported$.set(
      'setAppBadge' in navigator && 'clearAppBadge' in navigator
    );

    effect(() => {
      if (this.notificationsService.status$() !== 'granted')
        console.warn(
          'Notifications permission not granted. On some platforms, the badge API may not work if notifications permission is not granted.'
        );
    });

    effect(() => {
      if (!this.supported$()) return;
      this.badge = this.chatService.unreadMessages$();
    });
  }

  // Methods
  set badge(content: number | 'dot' | undefined) {
    console.log('BadgeService.setBadge', {
      postMessageAPI:
        'Available: ' + navigator.serviceWorker.controller?.postMessage,
      content,
    });
    // ? Notify our WebServices of the current badge content
    navigator.serviceWorker.controller?.postMessage({
      type: 'badge-content',
      content: content === 'dot' ? 0 : content,
    });

    if (!this.supported$()) {
      console.warn('Badge API not supported in this browser.');
      return;
    }

    // ? If no content is provided, or if a 0 is provided, the badge will be cleared
    if (content === undefined || content === 0) {
      console.log('Badge.clearBadge');
      navigator.clearAppBadge();
    }
    // ? If the content is 'dot', a dot will be displayed on the badge
    else if (content === 'dot') navigator.setAppBadge(0);
    // ? Otherwise, the badge will display the provided content (a number)
    else {
      console.log('Badge.setBadge', content);
      navigator.setAppBadge(content);
    }
  }
}
