import { OutgoingEvent } from '../zonetacts.events';

export class OTOMessageOutgoingEventDTO {
  private readonly event: OutgoingEvent.OTO_MESSAGE;

  readonly toIdUser!: number;
  readonly message!: string;
  readonly internalId!: string;
  readonly idMessageReply: string | undefined;

  constructor(message: {
    toIdUser: number;
    message: string;
    internalId: string;
    idMessageReply: string | undefined;
  }) {
    this.event = OutgoingEvent.OTO_MESSAGE;
    Object.assign(this, message);
  }
}
