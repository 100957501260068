import { Injectable } from '@angular/core';

import { AppError } from '@errors/app.error';

import { MessageType } from '@enums/messageType.enum';
import { ModificationState } from '@enums/modificationState.enum';
import { RoomType } from '@enums/roomType.enum';

import { DiscoverDeletedIncomingEventDTO } from './models/discover-deleted.incoming.dto';
import { DiscoverModifiedIncomingEventDTO } from './models/discover-modified.incoming.dto';
import { JoinedRoomIncomingEventDTO } from './models/joined-room.incoming.dto';
import { MessageStatusIncomingEventDTO } from './models/message-status.incoming.dto';
import { NewDiscoverIncomingEventDTO } from './models/new-discover.incoming.dto';
import { NewUserIncomingEventDTO } from './models/new-user.incoming.dto';
import { OTOMessageIncomingEventDTO } from './models/oto-message.incoming.dto';
import { OTOReadedMessagesIncomingEventDTO } from './models/oto-readed-messages.incoming.dto';
import { RoomAdminsIncomingEventDTO } from './models/room-admins.incoming.dto';
import { RoomMessageIncomingEventDTO } from './models/room-message.incoming.dto';
import { RoomModifiedIncomingEventDTO } from './models/room-modified.incoming.dto';
import { RoomReadedMessagesIncomingEventDTO } from './models/room-readed-messages.incoming.dto';
import { UserDeletedIncomingEventDTO } from './models/user-deleted.incoming.dto';
import { UserModifiedIncomingEventDTO } from './models/user-modified.incoming.dto';
import { Discover } from '@models/discover.class';
import { Message } from '@models/message.type';
import { AudioMessage } from '@models/messages/audio-message.class';
import { IBaseMessage } from '@models/messages/base/Message.base';
import { FileMessage } from '@models/messages/file-message.class';
import { ImageMessage } from '@models/messages/image-message.class';
import { TextMessage } from '@models/messages/text-message.class';
import { VideoMessage } from '@models/messages/video-message.class';
import { User } from '@models/user.class';

import { AuthStore } from '@state/stores/auth.store';
import { DiscoversStore } from '@state/stores/discovers.store';
import { MessagesStore } from '@state/stores/messages.store';
import { OTOsStore } from '@state/stores/otos.store';
import { RoomsStore } from '@state/stores/rooms.store';
import { UIStore } from '@state/stores/ui.store';
import { UsersStore } from '@state/stores/users.store';

import { GenericRoomsService } from '@services/api/rooms/generic-rooms.service';
import { ToastService } from '@services/toast/toast.service';

import { IncomingEvent } from './zonetacts.events';

@Injectable({
  providedIn: 'root',
})
export class ZTSocketHandlerService {
  // Properties
  me$ = this.authStore.me;

  // Lyfecycle hooks
  constructor(
    private readonly authStore: AuthStore,
    private readonly usersStore: UsersStore,
    private readonly otosStore: OTOsStore,
    private readonly roomsStore: RoomsStore,
    private readonly discoversStore: DiscoversStore,
    private readonly messagesStore: MessagesStore,
    private readonly uiStore: UIStore,
    private readonly roomsService: GenericRoomsService,
    private toastService: ToastService
  ) {}

  handlers: {
    [key in IncomingEvent]: (data: any) => void;
  } = {
    [IncomingEvent.APP_ERROR]: (data: AppError) => this.onAppError(data),

    // Users
    [IncomingEvent.NEW_USER]: (data: NewUserIncomingEventDTO) =>
      this.onNewUser(data),
    [IncomingEvent.USER_MODIFIED]: (data: UserModifiedIncomingEventDTO) =>
      this.onModifiedUser(data),
    [IncomingEvent.USER_DELETED]: (data: UserDeletedIncomingEventDTO) =>
      this.onDeletedUser(data),

    // Rooms
    [IncomingEvent.ROOM_MODIFIED]: (data: RoomModifiedIncomingEventDTO) =>
      this.onModifiedRoom(data),
    [IncomingEvent.ROOM_ADMINS]: (data: RoomAdminsIncomingEventDTO) =>
      this.onModifiedRoomAdmins(data),

    [IncomingEvent.JOINED_ROOM]: (data: JoinedRoomIncomingEventDTO) =>
      this.onJoinedRoom(data),
    [IncomingEvent.QUIT_ROOM]: (data) => this.onDeletedRoom(data),
    [IncomingEvent.OTO_DELETED]: (data) => this.onDeleteOTO(data),

    // Discovers
    [IncomingEvent.NEW_DISCOVER]: (data: NewDiscoverIncomingEventDTO) =>
      this.onNewDiscover(data),
    [IncomingEvent.DISCOVER_MODIFIED]: (
      data: DiscoverModifiedIncomingEventDTO
    ) => this.onModifiedDiscover(data),
    [IncomingEvent.DISCOVER_DELETED]: (data: DiscoverDeletedIncomingEventDTO) =>
      this.onDeletedDiscover(data),

    // Messages
    [IncomingEvent.MESSAGE_STATUS]: (data: MessageStatusIncomingEventDTO) =>
      this.onMessageStatus(data),

    [IncomingEvent.OTO_MESSAGE]: (data: OTOMessageIncomingEventDTO) =>
      this.onNewOTOMessage(data),
    [IncomingEvent.OTO_MESSAGE_MODIFIED]: (data) =>
      this.onModifiedOTOMessage(data),
    [IncomingEvent.OTO_READED_MESSAGES]: (
      data: OTOReadedMessagesIncomingEventDTO
    ): void => this.onReadedOTOMessages(data),

    [IncomingEvent.ROOM_MESSAGE]: (data: RoomMessageIncomingEventDTO) =>
      this.onNewRoomMessage(data),
    [IncomingEvent.ROOM_MESSAGE_MODIFIED]: (data) =>
      this.onModifiedRoomMessage(data),
    [IncomingEvent.ROOM_READED_MESSAGES]: (
      data: RoomReadedMessagesIncomingEventDTO
    ) => this.onReadedRoomMessages(data),

    [IncomingEvent.CONFIRMATION_FILE]: (data) => this.onConfirmationFile(data),
  };

  private onAppError = (data: AppError): void => {
    console.error('App Error received through socket', data);
  };

  // Users
  private onNewUser = (data: NewUserIncomingEventDTO): void => {
    const user: User = new User({
      id: data.idUser,
      name: data.name,
      surname: data.surname,
      bio: data.additionalInfo,
    });
    this.usersStore.add(user, { override: true });
  };
  private onModifiedUser = (data: UserModifiedIncomingEventDTO): void => {
    const fields: Partial<User> = {
      name: data.fields.name,
      surname: data.fields.surname,
      bio: data.fields.additionalInfo,
      // picture: data.fields.image,
    };
    Object.keys(fields).forEach(
      (key) =>
        fields[key as keyof User] === undefined &&
        delete fields[key as keyof User]
    );

    this.usersStore.update(data.idUser, () => fields);
  };
  private onDeletedUser = (data: UserDeletedIncomingEventDTO): void => {
    // ? Reset UI if chat was with the deleted user
    if (
      this.uiStore.chat()?.id === data.idUser &&
      this.uiStore.chat()?.type === RoomType.OTO
    )
      this.uiStore.reset();

    this.usersStore.remove(data.idUser);
  };

  // Rooms
  private onJoinedRoom = (data: JoinedRoomIncomingEventDTO): void => {
    this.roomsService.getRoomById(data.idRoom).subscribe();
  };
  private onModifiedRoom = (data: RoomModifiedIncomingEventDTO): void => {
    // TODO: Handle update params (+ dates)
    this.roomsStore.update(data.idRoom, (room) => ({
      name: data.fields.name,
      // description: data.fields.description,
      // picture: data.fields.image,
      state: ModificationState.MODIFIED,
      dates: {
        ...room.dates,
        modifiedDate: new Date(),
        lastEvent: new Date(),
      },
    }));
  };
  private onModifiedRoomAdmins = (data: RoomAdminsIncomingEventDTO): void => {
    this.roomsStore.update(data.idRoom, (room) => ({
      administrators: data.administrators,
    }));
  };

  private onDeletedRoom = (data: any): void => {
    // this.roomsStore.remove(data.idRoom);
    this.roomsStore.update(data.idRoom, (room) => ({
      state: ModificationState.DELETED,
      dates: {
        ...room.dates,
        modifiedDate: new Date(),
        lastEvent: new Date(),
      },
    }));

    // TODO: Send a different event to handle discovers
    this.discoversStore.update(data.idRoom, () => ({
      vinculated: false,
    }));
  };
  private onDeleteOTO = (data: any): void => {
    // this.otosStore.remove(data.idOTO);
    this.otosStore.update(data.idOTO, (message) => ({
      state: ModificationState.DELETED,
      dates: {
        ...message.dates,
        modifiedDate: new Date(),
        lastEvent: new Date(),
      },
    }));
  };

  // Discovers
  private onNewDiscover = (data: NewDiscoverIncomingEventDTO): void => {
    const discover: Partial<Discover> = {
      id: (data as any).idDiscover,
      name: (data as any).name,
    };
    this.discoversStore.add(discover as Discover);
  };
  private onModifiedDiscover = (
    data: DiscoverModifiedIncomingEventDTO
  ): void => {
    const discover = this.discoversStore.find(
      (d) => d.idDiscover === data.idDiscover,
      {
        multiple: false,
      }
    );
    if (!discover) return;
    this.discoversStore.update(discover.id, () => ({
      index: data.fields.position || discover.index,
      name: data.fields.name || discover.name,
      // picture: data.fields.image || discover.picture,
    }));
  };
  private onDeletedDiscover = (data: DiscoverDeletedIncomingEventDTO): void => {
    const discover = this.discoversStore.find(
      (d) => d.idDiscover === data.idDiscover,
      {
        multiple: false,
      }
    );
    if (!discover) return;
    else this.discoversStore.remove(discover.id);

    if (
      this.uiStore.chat()?.id === discover.id &&
      this.uiStore.chat()?.type === RoomType.Discover
    )
      this.uiStore.reset();
  };

  // Messages
  private onMessageStatus = (data: MessageStatusIncomingEventDTO): void => {
    if (data.appError)
      this.toastService.error(data.appError.error.detail['en']);

    // TODO: Revise this when the server returns an error
    this.messagesStore.update(data.internalId, () => ({
      synchronized: data.response === 'OK',
    }));
  };

  private onNewOTOMessage = (data: OTOMessageIncomingEventDTO): void => {
    const message = this.generateMessage(data);
    // this.messagesStore.add(message);

    const id = data.toIdUser === this.me$() ? data.idUser : data.toIdUser;

    this.otosStore.update(id, (oto) => ({
      dates: {
        ...oto.dates,
        lastEvent: message.dates.sentDate,
      },
      unread:
        message.ids.sender === this.me$()
          ? oto.unread
          : oto.unread
            ? oto.unread + 1
            : 1,
    }));
  };
  private onModifiedOTOMessage = (data: any): void => {
    // TODO: Handle modified messages (deleted ones, etc...)
  };
  private onReadedOTOMessages = (
    data: OTOReadedMessagesIncomingEventDTO
  ): void => {
    this.otosStore.update(data.idUser, (oto) => ({
      unread: Math.max((oto.unread || 0) - data.messagesToClean, 0),
    }));
  };

  private onNewRoomMessage = (data: RoomMessageIncomingEventDTO): void => {
    const message = this.generateMessage(data);
    this.messagesStore.add(message);

    this.roomsStore.update(data.idRoom, (room) => ({
      dates: {
        ...room.dates,
        lastEvent: message.dates.sentDate,
      },
      unread:
        message.ids.sender === this.me$()
          ? room.unread
          : room.unread
            ? room.unread + 1
            : 1,
    }));
  };
  private onModifiedRoomMessage = (data: any): void => {
    // TODO: Handle modified messages
  };
  private onReadedRoomMessages = (
    data: RoomReadedMessagesIncomingEventDTO
  ): void => {
    this.roomsStore.update(data.idRoom, (room) => ({
      unread: Math.max((room.unread || 0) - data.messagesToClean, 0),
    }));
  };

  private onConfirmationFile = (data: any): void => {};

  private generateMessage(
    data: OTOMessageIncomingEventDTO | RoomMessageIncomingEventDTO
  ): Message {
    let message: Message;
    const base: Omit<IBaseMessage, 'type'> = {
      id: data.internalId,
      ids: {
        server: data._id,
        sender: data.idUser,
        receiver:
          'toIdUser' in data
            ? (data as OTOMessageIncomingEventDTO).toIdUser
            : undefined,
        room:
          'idRoom' in data
            ? (data as RoomMessageIncomingEventDTO).idRoom
            : undefined,
        replyId: data.replyMessage?.idMessage,
      },
      dates: {
        sentDate: new Date(data.date),
      },
      synchronized: true,
      // status: data.modifiedState,
      reply: data.replyMessage && {
        id: data.replyMessage.idMessage,
        sender: data.replyMessage.idUser,
        message: data.replyMessage.text,
        date: new Date(data.replyMessage.date),
        file: data.replyMessage.file,
        state: data.replyMessage.modifiedState,
      },
    };

    switch (data.fileType) {
      case MessageType.IMAGE:
        message = new ImageMessage({
          ...base,
          message: data.message,
          thumbnail: data.thumbnail!,
          extension: data.extension!,
        });
        break;
      case MessageType.VIDEO:
        message = new VideoMessage({
          ...base,
          message: data.message,
          thumbnail: data.thumbnail!,
          extension: data.extension!,
          duration: data.fileDuration
            ? data.fileDuration
            : (() => {
                const match = data.message.match(/(\d+):(\d+)/);
                if (match) {
                  const minutes = parseInt(match[1], 10);
                  const seconds = parseInt(match[2], 10);
                  return (minutes * 60 + seconds) * 1000;
                }
                return 0;
              })(),
        });
        break;
      case MessageType.AUDIO:
        message = new AudioMessage({
          ...base,
          message: undefined,
          thumbnail: data.thumbnail!,
          extension: data.extension!,
          duration: data.fileDuration!,
        });
        break;
      case MessageType.FILE:
        message = new FileMessage({
          ...base,
          message: undefined,
          extension: data.extension!,
          thumbnail: data.thumbnail,
        });
        break;
      case MessageType.LOCATION:
      case MessageType.CONTACT:
      case MessageType.POLL:
      case MessageType.TEXT:
      default:
        message = new TextMessage({
          ...base,
          message: data.message,
        });
        break;
    }
    return message;
  }
}
