import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  input,
  signal,
  ViewChild,
} from '@angular/core';

import { ThemeType, transformIntoTailwindTheme } from '@models/theme.type';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgClass, NgStyle],
})
export class ProgressBarComponent {
  // Host bindings
  @HostBinding('class') class = `block overflow-hidden`; // ? Flex container with items centered

  // Inputs & Outputs
  theme = input<ThemeType>(); // ? Optional theme of the progress bar

  container = input<string>(); // ? Optional classes that will be applied to the progress bar container
  bar = input<string>(); // ? Optional classes that will be applied to the progress bar

  duration = input.required<number>(); // ? Duration in milliseconds
  frequency = input<number>(10); // ? Frequency in milliseconds

  callback = input<() => void>(); // ? Callback function to be called when the progress bar reaches 100%

  // Properties
  @ViewChild('progressBar') private progressBarRef!: ElementRef<HTMLElement>;
  private get progressBarElement(): HTMLElement {
    return this.progressBarRef.nativeElement;
  }
  progress = signal(0); // ? Progress in percentage

  // Lifecycle hooks
  constructor() {}

  ngOnInit(): void {
    // ? Set the theme of the progress bar
    if (this.theme())
      this.class += ` bg-${transformIntoTailwindTheme(
        this.theme()!
      )}-light border-${transformIntoTailwindTheme(this.theme()!)}-dark`;

    // ? Set the initial width of the progress bar to 0
    this.progress.set(0);

    const interval = setInterval(() => {
      this.progress.set(
        this.progress() + (100 / this.duration()) * this.frequency()
      );
      if (this.progress() >= 100) {
        clearInterval(interval);
        if (
          this.callback !== undefined &&
          typeof this.callback === 'function'
        ) {
          console.log('Calling callback');
          this.callback();
          console.log('Callback called');
        } else {
          console.log('Callback not defined');
        }
      }
    }, this.frequency());
  }

  ngAfterViewInit(): void {
    // ? Set the theme of the progress bar
    if (this.progressBarElement && this.theme())
      this.progressBarElement.classList.add(
        `bg-${transformIntoTailwindTheme(this.theme()!)}`
      );
  }
}
