import { Injectable } from '@angular/core';

import {
  ToastOptions as HotToastOptions,
  HotToastService,
  ToastPersistConfig,
} from '@ngxpert/hot-toast';

import { SemanticLevel } from '@models/semantic.enum';

import { NotificationComponent } from '@components/notification/notification.component';

export interface ToastOptions {
  id: string;
  type: SemanticLevel;
  icon: string;
  duration?: number; // ? In milliseconds
  position:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right';
  progressBar?: 'top' | 'bottom' | 'none';
  actions?: ToastActionButton[];
  dismissible: boolean;
  persist: ToastPersistConfig;
}

export interface ToastActionButton {
  text: string;
  icon?: string;
  class?: string;
  click: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toaster: HotToastService) {}

  info(message: string) {
    // info(message: string, options?: Partial<ToastOptions>) {
    const id = `info-${Date.now()}`;
    this.custom(message, {
      id,
      type: SemanticLevel.Info,
      duration: 5000,
      position: 'top-center',
      dismissible: false,
      // persist: { enabled: false },
      // ...options,
    });
  }

  error(message: string) {
    // error(message: string, options?: Partial<ToastOptions>) {
    const id = `error-${Date.now()}`;
    this.custom(message, {
      id,
      type: SemanticLevel.Error,
      duration: 5000,
      position: 'top-center',
      dismissible: true,
      // persist: { enabled: false },
      // ...options,
    });
  }

  custom(message: string, options?: Partial<ToastOptions>) {
    const id = options?.id || `${Date.now()}`;
    const opts: Partial<HotToastOptions<any>> = {
      icon: options?.icon,
      duration: options?.duration,
      autoClose:
        typeof options?.duration === 'number' &&
        !isNaN(options.duration) &&
        options.duration > 0,
      position: options?.position,
      dismissible: options?.dismissible,
      persist: options?.persist,
      style: {
        maxWidth: '95vw',
      },
    };

    this.toaster.show(NotificationComponent, {
      id,
      data: {
        id,
        type: options?.type || 'default',
        message,
        options: opts,
        actions: options?.actions,
        progressBar: opts.autoClose
          ? options?.progressBar
            ? options.progressBar
            : 'bottom'
          : 'none',
      },
      ...opts,
    });
  }

  close(id: string) {
    this.toaster.close(id);
  }
}
