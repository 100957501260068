import { Observable, of } from 'rxjs';

import { Exclude } from 'class-transformer';

import { FilesService } from '@services/files/files.service';

export interface IBaseUser {
  name: string;
  surname: string;
  bio?: string;
  picture?: Blob;
}

export interface IUser extends IBaseUser {
  id: number;
  email?: string;
  color?: string;
}

export class User implements IUser {
  readonly id!: number;
  readonly name!: string;
  readonly surname!: string;
  readonly email?: string;
  readonly bio?: string;
  readonly color?: string;

  @Exclude() // ? This is to avoid Blob serialization
  readonly picture?: Blob;

  constructor(user: IUser) {
    Object.assign(this, user);
    if (!this.color)
      this.color = `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')}`;
  }

  get fullName(): string {
    return `${this.name} ${this.surname}`;
  }

  get pictureURL$(): Observable<string> {
    if (this.picture) return FilesService.blobToURL(this.picture);
    else return of('');
  }
}
