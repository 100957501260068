export enum IncomingEvent {
  APP_ERROR = 'appError',

  // Users
  NEW_USER = 'new-user',
  USER_MODIFIED = 'user-modified',
  USER_DELETED = 'user-deleted',

  // Rooms
  ROOM_MODIFIED = 'room-modified',
  ROOM_ADMINS = 'room-admins',

  JOINED_ROOM = 'joined room',
  QUIT_ROOM = 'quit room',
  OTO_DELETED = 'one-to-one-deleted',

  // Discovers
  NEW_DISCOVER = 'new-discover',
  DISCOVER_MODIFIED = 'discover-modified',
  DISCOVER_DELETED = 'discover-deleted',

  // Messages
  MESSAGE_STATUS = 'ack-msg',

  OTO_MESSAGE = 'chat message user',
  OTO_MESSAGE_MODIFIED = 'oto-message-modified',
  OTO_READED_MESSAGES = 'oto-readed-messages',

  ROOM_MESSAGE = 'chat message room',
  ROOM_MESSAGE_MODIFIED = 'room-message-modified',
  ROOM_READED_MESSAGES = 'room-readed-messages',

  CONFIRMATION_FILE = 'confirmation-file',
}

export enum OutgoingEvent {
  // Messages
  OTO_MESSAGE = 'chat message user',
  ROOM_MESSAGE = 'chat message room',
  CONFIRMATION_FILE = 'confirmation-file',
}
