import { computed, inject, isDevMode } from '@angular/core';

import {
  getState,
  patchState,
  signalStore,
  watchState,
  withComputed,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import {
  addEntities,
  addEntity,
  EntityState,
  removeAllEntities,
  removeEntities,
  removeEntity,
  setAllEntities,
  setEntities,
  setEntity,
  updateAllEntities,
  updateEntities,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';

import { instanceToPlain } from 'class-transformer';

import { sortByDate } from '@utils/time.utils';

import { RoomType } from '@enums/roomType.enum';

import { Chat } from '@models/chat.interface';
import { IMessage, instanceToClass, Message } from '@models/message.type';

import { StorageService } from '@services/storage/storage.service';

const TAG = 'Messages Store';

type Entity = IMessage;
type ID = Entity['id'];
type Class = Message;
type R<T> = T extends undefined
  ? Class[]
  : T extends ID[]
    ? Class[]
    : Class | undefined;

export type MessagesState = EntityState<Entity>;
export type MessagesStore = InstanceType<typeof MessagesStore>;

export const MessagesStore = signalStore(
  withEntities<Entity>(),
  withComputed(({ entities }) => ({
    count: computed(() => entities().length),
    sorted: computed(() =>
      entities().sort((a, b) => {
        const dateA = new Date(a.dates.sentDate);
        const dateB = new Date(b.dates.sentDate);
        return sortByDate(dateA, dateB, { ascending: true });
      })
    ),
  })),
  withMethods((store, storageService = inject(StorageService)) => ({
    // Entity methods
    // ? We are making this private because we don't want to expose the entityMap directly
    get<T extends undefined | ID | ID[]>(ids?: T): R<T> {
      // const map = getState(store).entityMap;
      const map = store.entityMap();

      if (!ids) return <R<T>>store.entities().map(instanceToClass);
      else if (Array.isArray(ids)) {
        return <R<T>>ids
          .map((id) => map[id])
          .filter((entity): entity is Entity => !!entity)
          .map(instanceToClass);
      } else {
        const entity = map[ids as ID];
        return <R<T>>(entity ? instanceToClass(entity) : undefined);
      }
    },

    all() {
      return this.get(undefined);
    },

    find<T extends boolean>(
      predicate: (entity: Entity) => boolean,
      { multiple }: { multiple: T }
    ): T extends true ? Class[] : Class | undefined {
      const entities = store.sorted().map(instanceToClass) as Entity[];

      if (multiple) {
        return entities.filter(predicate) as T extends true ? Class[] : never;
      } else {
        return (
          entities.find(predicate) ? entities.find(predicate) : undefined
        ) as T extends true ? never : Class | undefined;
      }
    },

    add(
      entities: Entity | Entity[],
      { override, update }: { override?: boolean; update?: boolean } = {
        override: false,
        update: false,
      }
    ): void {
      if (override) {
        if (Array.isArray(entities)) patchState(store, setEntities(entities));
        else patchState(store, setEntity(entities));
      } else {
        if (update) {
          if (Array.isArray(entities)) {
            const map = new Map<ID, Entity>();
            entities.forEach((entity) => map.set(entity.id, entity));
            this.update(
              entities.map((entity) => entity.id),
              (entity) => map.get(entity.id) ?? entity
            );
          } else this.update(entities.id, () => entities);
        }

        // ? AddEntities will not override the entities, it will only add the missing ones
        if (Array.isArray(entities)) patchState(store, addEntities(entities));
        else patchState(store, addEntity(entities));
      }
    },

    update(ids: ID | ID[], changes: (entity: Entity) => Partial<Entity>): void {
      if (Array.isArray(ids))
        patchState(store, updateEntities({ ids, changes }));
      else patchState(store, updateEntity({ id: ids, changes }));
    },
    remove(ids: ID | ID[]): void {
      if (Array.isArray(ids)) patchState(store, removeEntities(ids));
      else patchState(store, removeEntity(ids));
    },

    // Entity collection methods
    replaceCollection(entities: Entity[]): void {
      patchState(store, setAllEntities(entities));
    },

    updateCollection(changes: (entity: Entity) => Partial<Entity>): void {
      patchState(store, updateAllEntities(changes));
    },

    deleteCollection(): void {
      patchState(store, removeAllEntities());
    },

    reset(): void {
      this.deleteCollection();
    },

    // Hydration methods
    hydrate: (): void => {
      console.debug(`[${TAG}] Starting hydration...`);
      if (storageService.check(TAG)) {
        const stored = storageService.get<MessagesState>(TAG, (stored) => ({
          ids: stored.ids,
          entityMap: Object.fromEntries(
            Object.entries(stored.entityMap).map(([key, value]) => [
              key,
              instanceToClass(value as Entity),
            ])
          ),
        }));
        console.debug(`[${TAG}] Hydrating...`, stored);
        if (stored !== null)
          // ? Only restore some properties of the stored state
          patchState(store, (state) => stored);
      } else {
        console.debug(`[${TAG}] No previous state found`);
      }
    },
    persist(): void {
      console.debug(`[${TAG}] Persisting state...`);
      storageService.set(TAG, instanceToPlain(getState(store)));
    },

    // Custom methods
    findByServerId(serverId: string) {
      return this.find((entity) => entity.ids.server === serverId, {
        multiple: false,
      });
    },
    findByChat(chat: Chat, predicate?: (entity: Entity) => boolean) {
      return this.find(
        (message) =>
          (predicate ? predicate(message) : true) &&
          // Room messages
          ((chat.type !== RoomType.OTO && chat.id === message.ids.room) ||
            // OTO messages
            (chat.type === RoomType.OTO &&
              // Sent messages
              (chat.id === message.ids.receiver ||
                // Received messages
                (chat.id === message.ids.sender &&
                  // TODO: Replace the !== undefined with === my id
                  message.ids.receiver !== undefined)))),
        {
          multiple: true,
        }
      );
    },

    getByDates(chat: Chat, predicate?: (entity: Entity) => boolean) {
      /*
      const messagesByDate = this.findByChat(chat, predicate).reduce(
        (acc, message) => {
          const date = new Date(
            new Date(message.dates.sentDate).setHours(0, 0, 0, 0)
          ).getTime();
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(message);
          return acc;
        },
        {} as Record<number, Message[]>
      );
      */

      return Object.entries(
        this.findByChat(chat, predicate).reduce(
          (acc, message) => {
            const date = new Date(
              new Date(message.dates.sentDate).setHours(0, 0, 0, 0)
            ).getTime();
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(message);
            return acc;
          },
          {} as Record<number, Message[]>
        )
      )
        .map(([date, messages]) => [
          new Date(parseInt(date)),
          ...messages,
          /*
          ! Not needed, they are already stored in the correct order
          .sort((a, b) =>
            a.dates.sentDate > b.dates.sentDate ? 1 : -1
          ),
          */
        ])
        .flat();
    },
    latestMessage(chat: Chat) {
      // ? The messages come sorted by date, so we can just return the last one
      return this.findByChat(chat).reduce(
        (latest, entity) => {
          if (
            !latest ||
            new Date(latest.dates.sentDate) < new Date(entity.dates.sentDate)
          )
            latest = entity;
          return latest;
        },
        undefined as Message | undefined
      );
      // return this.findByChat(chat).slice(-1)[0];
    },
  })),
  withHooks({
    onInit: (store) => {
      if (isDevMode()) console.debug(`[${TAG}] Store initialized`);

      // ? Effects
      // https://next.ngrx.io/guide/signals/signal-store/state-tracking#using-getstate-and-effect
      // effect(() => {}, {});

      store.hydrate();

      watchState(store, (state) => {
        if (isDevMode()) console.debug(`[${TAG}] State updated:`, state);
        store.persist();
      });

      /*
      ! For messages, we want to store all changes
      if (isDevMode())
        watchState(store, (state) =>
          console.debug(`[${TAG}] State updated:`, state)
        );

      // ? Instead of watching the state and persisting it everytime it changes, we persist it every x minutes
      const SAVE_INTERVAL_MINUTES = 5; // Set the interval time in minutes
      interval(SAVE_INTERVAL_MINUTES * 60 * 1000)
        .pipe(takeUntilDestroyed())
        .subscribe(() => {
          if (isDevMode())
            console.debug(`[${TAG}] Persisting state at interval`);
          store.persist();
        });
      */
    },
    onDestroy: (store) => {
      if (isDevMode())
        console.debug(`[${TAG}] Store destroyed`, getState(store));
      store.persist();
    },
  })
);
