import { FileExtension } from '@enums/fileExtensions.enum';
import { MessageType } from '@enums/messageType.enum';

import { FilesService } from '@services/files/files.service';

import { BaseFileMessage, IBaseFileMessage } from './base/FileMessage.base';

// ? Defined in the backend (config.adapter.ts)
enum AllowedExtensions {
  JPG = FileExtension.JPG,
  JPEG = FileExtension.JPEG,
  PNG = FileExtension.PNG,
  GIF = FileExtension.GIF,
}
export const AllowedMIMEs = Object.values(AllowedExtensions).map((ext) =>
  FilesService.mime(ext)
);

export interface IImageMessage extends IBaseFileMessage {
  type: MessageType.IMAGE;

  extension: AllowedExtensions;
  duration?: never;
  // size: number;
  thumbnail: string;
}

export class ImageMessage extends BaseFileMessage implements IImageMessage {
  override readonly type: MessageType.IMAGE = MessageType.IMAGE;

  override readonly extension!: AllowedExtensions;
  override readonly duration?: never;
  // override readonly size!: number;
  override readonly thumbnail!: string;

  constructor(
    message: Omit<IImageMessage, 'type' | 'extension'> & { extension: string }
  ) {
    super({ ...message, type: MessageType.IMAGE });
    // Object.assign(this, message); // ? This would override class properties of the base message.
    this.extension = intoAllowedImageExtension(message.extension);
    this.thumbnail = message.thumbnail;
  }
}

function intoAllowedImageExtension(extension: string) {
  const allowed = Object.values(AllowedExtensions);
  if (!allowed.includes(extension as AllowedExtensions)) {
    throw new Error(`Extension ${extension} is not allowed`);
  }
  return extension as AllowedExtensions;
}
